<script setup lang="ts">
import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { ref, onMounted } from 'vue';
import { Form } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';

const code = ref('');
const email = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const confirmResetPasswordErrorMessage = ref('');
const router = useRouter();
const route = useRoute();
const isEmailDisabled = ref(false);

onMounted((): void => {
  const emailQueryParam = route.query.email as string | undefined;
  if (emailQueryParam) {
    email.value = decodeURIComponent(emailQueryParam);
    isEmailDisabled.value = true;
  }
});

const onResendCode = async (): Promise<void> => {
  await resetPassword({
    username: email.value,
  });
};

const onSubmit = async (): Promise<void> => {
  try {
    await confirmResetPassword({
      username: email.value,
      confirmationCode: code.value,
      newPassword: newPassword.value,
    });

    await router.push({ name: 'authentication' });
  } catch (error: any) {
    confirmResetPasswordErrorMessage.value = error.message;
    isEmailDisabled.value = false;
  }
};
</script>

<template>
  <div
    class="authenticator-custom reset-password"
    :class="{
      'reset-password--error-message': confirmResetPasswordErrorMessage,
    }"
  >
    <div data-amplify-authenticator data-variation="default">
      <div data-amplify-container>
        <div data-amplify-router data-amplify-router-content>
          <div>
            <Form
              data-amplify-form
              data-amplify-authenticator-confirmresetpassword
              v-slot="{ meta }"
              @submit="onSubmit"
            >
              <fieldset
                class="amplify-flex amplify-authenticator__column"
                data-amplify-fieldset
              >
                <h3
                  data-amplify-heading
                  class="amplify-heading amplify-heading--3"
                >
                  {{ $t('amplify.Reset password') }}
                </h3>

                <div class="amplify-flex amplify-authenticator__column">
                  <BaseFieldText
                    data-amplify-input
                    data-test="email"
                    v-model="email"
                    :disabled="isEmailDisabled"
                    id="email"
                    autofocus
                    name="email"
                    autocomplete="email"
                    required
                    :placeholder="$t('amplify.Email')"
                    type="email"
                    aria-invalid="false"
                    autocapitalize="off"
                    rules="required|email"
                    :label="$t('amplify.Email')"
                  />

                  <BaseFieldText
                    data-amplify-input
                    data-test="code"
                    v-model="code"
                    id="code"
                    autofocus
                    data-amplify-password="true"
                    name="confirmation_code"
                    autocomplete="one-time-code"
                    required
                    :placeholder="$t('amplify.Verification Code')"
                    type="text"
                    aria-invalid="false"
                    autocapitalize="off"
                    rules="required"
                    :label="$t('amplify.Verification Code')"
                  />

                  <BaseFieldText
                    data-amplify-input
                    data-test="new-password"
                    v-model="newPassword"
                    id="new-password"
                    data-amplify-password="true"
                    name="newPassword"
                    autocomplete="new-password"
                    required
                    :placeholder="$t('amplify.New Password')"
                    type="password"
                    aria-invalid="false"
                    autocapitalize="off"
                    passwordReveal
                    rules="required|password"
                    :label="$t('amplify.New Password')"
                  />

                  <BaseFieldText
                    data-amplify-input
                    data-test="confirm-password"
                    v-model="confirmPassword"
                    id="confirm-password"
                    data-amplify-password="true"
                    name="confirm_password"
                    autocomplete="new-password"
                    required
                    :placeholder="$t('amplify.Confirm password')"
                    type="password"
                    aria-invalid="false"
                    autocapitalize="off"
                    passwordReveal
                    rules="required|password|password-repeat:@newPassword"
                    :label="$t('amplify.Confirm password')"
                  />
                </div>

                <footer
                  class="amplify-flex amplify-authenticator__column reset-password__footer"
                >
                  <div
                    v-if="confirmResetPasswordErrorMessage"
                    class="amplify-flex amplify-alert amplify-alert--error amplify-authenticator__base"
                    data-variation="error"
                    role="alert"
                  >
                    <div
                      class="amplify-flex amplify-authenticator__icon-wrapper"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="amplify-icon"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                        ></path>
                      </svg>
                      <div>
                        {{ confirmResetPasswordErrorMessage }}
                      </div>
                    </div>
                    <button
                      class="amplify-button amplify-field-group__control amplify-button--link amplify-field-group__control amplify-alert__dismiss"
                      type="button"
                      data-fullwidth="false"
                      data-variation="link"
                      data-loading="false"
                      data-disabled="false"
                      aria-label="Dismiss alert"
                      fullwidth="false"
                      data-amplify-button
                      @click="confirmResetPasswordErrorMessage = ''"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="amplify-icon"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <button
                    class="amplify-button amplify-field-group__control amplify-button--primary amplify-field-group__control amplify-authenticator__font"
                    data-test="submit"
                    type="submit"
                    data-fullwidth="false"
                    data-variation="primary"
                    data-loading="false"
                    data-disabled="false"
                    fullwidth="false"
                    data-amplify-button
                  >
                    {{ $t('amplify.Submit') }}</button
                  ><button
                    v-if="meta.valid && confirmResetPasswordErrorMessage"
                    class="amplify-button amplify-field-group__control amplify-button--link amplify-button--small amplify-field-group__control amplify-authenticator__font"
                    type="button"
                    data-fullwidth="false"
                    data-size="small"
                    data-variation="link"
                    data-loading="false"
                    data-disabled="false"
                    fullwidth="false"
                    data-amplify-button
                    @click="onResendCode"
                  >
                    {{ $t('amplify.Resend Code') }}
                  </button>
                </footer>
              </fieldset>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reset-password {
  margin-top: 20px;
  position: relative;

  .amplify-flex .amplify-authenticator__column {
    gap: 0;
  }

  &--error-message {
    .reset-password__footer {
      margin-bottom: 40px;
    }
  }
}
</style>
