<template>
  <div>
    <vue-tel-input
      v-model="phone"
      @on-input="input"
      @validate="validate"
      mode="international"
      :preferredCountries="['de', 'gb', 'es', 'fr', 'it', 'nl']"
      :input-options="{ placeholder: $t('profile.label.phone'), required }"
      :style-classes="{ input: true, 'is-danger': !valid }"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface PhoneNumberCountry {
  areaCodes: string;
  dialCode: string;
  iso2: string;
  name: string;
  priority: number;
}

interface PhoneNumber {
  country?: PhoneNumberCountry;
  countryCallingCode?: string;
  countryCode?: string;
  formatted?: string;
  nationalNumber?: string;
  number?: string;
  valid?: boolean;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const phone = this.modelValue;

    return {
      valid: false,
      phone,
    };
  },
  methods: {
    input(phoneString: string, phoneObject: PhoneNumber): void {
      this.phone = phoneString;
      this.$emit('update:modelValue', phoneString);
      this.valid = phoneObject.valid || false;
      this.$emit('validate', this.valid);
    },
    validate(phoneObject: PhoneNumber): boolean {
      this.valid = phoneObject.valid || false;
      this.$emit('validate', this.valid);

      return this.valid;
    },
  },
});
</script>
