import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dish-form" }
const _hoisted_2 = { class: "dish-form__intro layout__content" }
const _hoisted_3 = {
  key: 0,
  class: "columns dish-form__restaurant-course"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "dish-form__group-header" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "subtitle" }
const _hoisted_10 = { class: "dish-form__main" }
const _hoisted_11 = { class: "dish-form__dish layout__content" }
const _hoisted_12 = {
  key: 0,
  class: "dish-form__group-header"
}
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "subtitle" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "button-row mobile-padding" }
const _hoisted_17 = {
  key: 2,
  class: "dish-form__min-dish-notification mobile-padding"
}
const _hoisted_18 = { class: "button-row mobile-padding layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_slider = _resolveComponent("slider")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isLandingPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_OField, {
                label: _ctx.$t('menuEngineering.label.optionRestaurant'),
                class: "column field--restaurant"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OSelect, {
                    modelValue: _ctx.restaurantId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setField('restaurantId', $event))),
                    expanded: "",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurants, (restaurant) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: restaurant.id,
                          value: restaurant.id
                        }, _toDisplayString(restaurant.restaurantName), 9, _hoisted_4))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_OField, {
                label: _ctx.$t('menuEngineering.label.selectCourse'),
                class: "column field--course"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OSelect, {
                    modelValue: _ctx.modelValue.course,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setField('course', $event))),
                    placeholder: _ctx.$t('menuEngineering.label.selectCourseLabel'),
                    expanded: "",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: course,
                          value: course
                        }, _toDisplayString(_ctx.$t('menuEngineering.label.course.' + course)), 9, _hoisted_5))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('menuEngineering.try-out.restaurant-type.title')), 1),
                _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('menuEngineering.try-out.restaurant-type.subtitle')), 1)
              ]),
              _createVNode(_component_OField, {
                label: _ctx.$t('menuEngineering.try-out.restaurant-type.label'),
                class: "mobile-padding field--restaurant-type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OInput, {
                    value: _ctx.modelValue.restaurantType,
                    placeholder: _ctx.$t('menuEngineering.placeholder.typeOfRestaurant')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"])
            ]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.isLandingPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('menuEngineering.try-out.dish-form.title')), 1),
                _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('menuEngineering.try-out.dish-form.subtitle')), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_OField, {
            label: _ctx.$t('menuEngineering.label.nameOfDish'),
            class: "mobile-padding field--name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: _ctx.modelValue.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.setField('name', $event))),
                placeholder: 
                _ctx.modelValue.course
                  ? _ctx.$t('menuEngineering.label.course.' + _ctx.modelValue.course)
                  : _ctx.$t('menuEngineering.label.nameOfDish')
              ,
                required: ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.isLandingPage)
            ? (_openBlock(), _createBlock(_component_OField, {
                key: 1,
                label: _ctx.$t('menuEngineering.label.selectCourse'),
                class: "mobile-padding field--course"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OSelect, {
                    modelValue: _ctx.modelValue.course,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.setField('course', $event))),
                    placeholder: _ctx.$t('menuEngineering.label.selectCourseLabel'),
                    expanded: "",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: course,
                          value: course
                        }, _toDisplayString(_ctx.$t('menuEngineering.label.course.' + course)), 9, _hoisted_15))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (slider, i) => {
            return (_openBlock(), _createBlock(_component_slider, {
              key: i,
              label: _ctx.$t(slider.label),
              tooltip: _ctx.$t(slider.tooltip),
              min: slider.min,
              max: slider.max,
              value: _ctx.modelValue[slider.field] || 0,
              onOnInput: ($event: any) => (_ctx.setField(slider.field, $event)),
              class: _normalizeClass(`field--${slider.field}`)
            }, null, 8, ["label", "tooltip", "min", "max", "value", "onOnInput", "class"]))
          }), 128)),
          _createElementVNode("div", _hoisted_16, [
            (!_ctx.isLandingPage)
              ? (_openBlock(), _createBlock(_component_OButton, {
                  key: 0,
                  rounded: "",
                  variant: "primary",
                  disabled: _ctx.saving || !_ctx.modelValue.name,
                  class: "button-row__button",
                  onClick: _ctx.submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.submitLabel)), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.modelValue.id && !_ctx.isLandingPage)
              ? (_openBlock(), _createBlock(_component_OButton, {
                  key: 1,
                  onClick: _ctx.toResults,
                  rounded: "",
                  outlined: "",
                  variant: "primary",
                  disabled: !_ctx.showResultsButton,
                  class: "button-row__button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.overview.showResults')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.modelValue.id && !_ctx.isLandingPage)
            ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t('menuEngineering.label.goodCalculation')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          (_ctx.isLandingPage)
            ? (_openBlock(), _createBlock(_component_OButton, {
                key: 0,
                rounded: "",
                variant: "primary",
                "native-type": "submit",
                disabled: _ctx.saving || !_ctx.modelValue.name,
                class: "button-row__button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.submitLabel)), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 32),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "layout__content" }, null, -1))
  ]))
}