import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sap-switcher"
}
const _hoisted_2 = {
  key: 0,
  class: "sap-switcher__title"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "sap-switcher__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return (_ctx.enabledSapCustomers && _ctx.enabledSapCustomers.length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["sap-switcher__current", { 'sap-switcher__current--active': _ctx.active }]),
          onClick: _cache[0] || (_cache[0] = 
        () => {
          _ctx.active = !_ctx.active;
        }
      )
        }, [
          (_ctx.sapCustomer)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.sapCustomer.CustName1), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_OIcon, {
            icon: "chevron_up",
            class: "sap-switcher__icon"
          })
        ], 2)), [
          [_directive_on_click_outside, 
        () => {
          _ctx.active = false;
        }
      ]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["sap-switcher__other", { 'sap-switcher__other--active': _ctx.active }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOtherOptions, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.CustomerNr,
              onClick: ($event: any) => (_ctx.setActiveSAPID(option.CustomerNr || '')),
              class: "sap-switcher__option"
            }, [
              (option.CustName1)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(option.CustName1), 1))
                : _createCommentVNode("", true)
            ], 8, _hoisted_3))
          }), 128))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}