import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-orders" }
const _hoisted_2 = { class: "layout__content layout__content--large" }
const _hoisted_3 = { class: "title title--direct" }
const _hoisted_4 = { class: "layout__intro-text layout__intro-text--orders" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OButton = _resolveComponent("OButton")!
  const _component_MfaWarning = _resolveComponent("MfaWarning")!
  const _component_OrderOverview = _resolveComponent("OrderOverview")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('orders.header.title')), 1)
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('orders.header.intro')), 1),
          (
            _ctx.hasMfa &&
            _ctx.$store.getters['auth/hasAccess']({
              action: 'POST',
              resource: '/new-order',
            })
          )
            ? (_openBlock(), _createBlock(_component_OButton, {
                key: 0,
                rounded: "",
                variant: "primary",
                tag: "router-link",
                to: { name: 'new-order' },
                "icon-right": "truck",
                class: "my-orders__new-order"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('orders.header.new-order-button')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.hasMfa)
            ? (_openBlock(), _createBlock(_component_MfaWarning, { key: 1 }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasMfa)
          ? (_openBlock(), _createBlock(_component_OrderOverview, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}