import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "contentblocks-wrapper columns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["contentblocks", {
      'contentblocks--has-backgroundcolor': _ctx.hasBackgroundColor,
      layout__content: !_ctx.hasBackgroundColor,
      'contentblocks--multinational': _ctx.multinational,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{
          layout__content: _ctx.hasLayoutContent,
          'is-half': block.type === 'dashboardcalltoaction',
          'is-full': block.type !== 'dashboardcalltoaction',
          'column--no-top-margin': _ctx.noTopMargin,
        }, "column"])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(`${block.type}Block`), { block: block }, null, 8, ["block"]))
        ], 2))
      }), 128))
    ]),
    (_ctx.pages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ layout__content: _ctx.hasLayoutContent })
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent('pagesblock'), { pages: _ctx.pages }, null, 8, ["pages"]))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}