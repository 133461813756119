<template>
  <div class="authenticator-custom" v-if="auth.route !== 'verifyUser'">
    <Authenticator :formFields="formFields" :login-mechanisms="['email']">
      <template v-slot:sign-in-header>
        <div class="amplify-heading amplify-heading--3">
          {{ $t('amplify.Sign in to your account') }}
        </div>
      </template>
      <template v-slot:sign-in-footer>
        <span class="authenticator-custom__forgot-your-password">
          {{ $t('amplify.Forgot your password? ') }}
          <a
            data-test="sign-in-forgot-password-link"
            @click="toForgotPassword()"
          >
            {{ $t('amplify.Reset password') }}
          </a>
        </span>
        <span class="authenticator-custom__sign-up-in-link">
          {{ $t('amplify.No account? ') }}
          <a data-test="sign-in-create-account-link" @click="toSignUp()">
            {{ $t('amplify.Create account') }}
          </a>
        </span>
      </template>

      <template v-slot:sign-up-header>
        <div class="amplify-heading amplify-heading--3">
          {{ $t('amplify.Create a new account') }}
        </div>
      </template>
      <template v-slot:sign-up-footer>
        <span class="authenticator-custom__sign-up-in-link">
          {{ $t('amplify.Have an account? ') }}
          <a data-test="sign-in-link" @click="toSignIn()">
            {{ $t('amplify.Sign in') }}
          </a>
        </span>
      </template>

      <template v-slot:forgot-password-header>
        <div class="amplify-heading amplify-heading--3">
          {{ $t('amplify.Reset your password') }}
        </div>
      </template>

      <template v-slot:confirm-sign-in-header>
        <div class="amplify-heading amplify-heading--3">
          {{ $t('amplify.Confirm Sign In') }}
        </div>
        <p class="amplify-text authentication__mfa-code-message">
          {{ $t('amplify.Apply code from MFA app') }}
        </p>
      </template>

      <template v-slot:confirm-sign-in-footer>
        <OCheckbox
          :modelValue="rememberDevice"
          @update:modelValue="toggleRememberDevice"
          root-class="amplify-text authenticator-custom__checkbox"
          >{{ $t('amplify.Remember this device') }}</OCheckbox
        >
      </template>
    </Authenticator>
  </div>
</template>

<script lang="ts">
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthenticatorCustom',
  data(): any {
    return {
      formFields: {
        signIn: {
          username: {
            order: 1,
            label: this.$t('amplify.Email address'),
            placeholder: this.$t('amplify.Enter your Email address'),
          },
          password: {
            order: 2,
          },
        },
        signUp: {
          email: {
            order: 1,
            label: this.$t('amplify.Email address'),
            placeholder: this.$t('amplify.Enter your Email address'),
          },
          password: {
            order: 2,
            placeholder: this.$t('amplify.Enter your password'),
          },
          confirm_password: {
            order: 3,
            label: this.$t('amplify.Confirm password'),
            placeholder: this.$t('amplify.Please confirm your password'),
          },
        },
      },
    };
  },
  computed: {
    rememberDevice(): boolean {
      return this.$store.state.auth.rememberDevice;
    },
  },
  methods: {
    toggleRememberDevice(): void {
      this.$store.commit('auth/setRememberDevice', !this.rememberDevice);
    },
    toSignIn(): void {
      const facade = useAuthenticator();
      facade.toSignIn();
    },
    toSignUp(): void {
      const facade = useAuthenticator();
      facade.toSignUp();
    },
    toForgotPassword(): void {
      const facade = useAuthenticator();
      facade.toForgotPassword();
    },
  },
  setup() {
    const auth = useAuthenticator();

    return { auth };
  },
});
</script>

<style lang="scss" scoped>
.authenticator-custom {
  position: relative;

  &__checkbox {
    color: $primary;
    font-size: 16px;
    order: 1;

    &.b-checkbox.checkbox :deep(.control-label) {
      padding: 2px 0 0 16px;
    }
  }

  :deep(.amplify-alert.amplify-alert--error) {
    order: 4;
    margin-bottom: 80px;
  }

  :deep(footer) {
    min-height: 110px;
    height: auto;
  }
}
</style>
