import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-order-footer__content" }
const _hoisted_2 = { class: "new-order-footer__totals" }
const _hoisted_3 = { class: "new-order-footer__nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewOrderFooterMessage = _resolveComponent("NewOrderFooterMessage")!
  const _component_OrderTotals = _resolveComponent("OrderTotals")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["new-order-footer", { 'new-order-footer--sticky': _ctx.isSticky }])
  }, [
    (_ctx.step === 'select-products')
      ? (_openBlock(), _createBlock(_component_NewOrderFooterMessage, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showTotals)
          ? (_openBlock(), _createBlock(_component_OrderTotals, {
              key: 0,
              items: _ctx.items,
              products: _ctx.relatedProducts
            }, null, 8, ["items", "products"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(_component_OButton, {
          variant: "primary large new-order-footer__button",
          rounded: "",
          outlined: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goPrevious())),
          "data-test": "previous-step"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`orders.new.footer.${_ctx.previousLabel}`)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_OButton, {
          variant: "primary large new-order-footer__button",
          rounded: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goNext())),
          disabled: !_ctx.canGoNext,
          "data-test": "next-step"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`orders.new.footer.${_ctx.nextLabel}`)), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createVNode(_component_OLoading, {
      active: _ctx.loading,
      "full-page": true
    }, null, 8, ["active"])
  ], 2))
}