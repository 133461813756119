import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "order-status" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "modal-card" }
const _hoisted_10 = { class: "modal-card-head" }
const _hoisted_11 = { class: "modal-card-title" }
const _hoisted_12 = { class: "status__label" }
const _hoisted_13 = { class: "modal-card-body" }
const _hoisted_14 = { class: "status__description" }
const _hoisted_15 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusPill = _resolveComponent("StatusPill")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OModal = _resolveComponent("OModal")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "order-status__data",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDetailedOrderStatus && _ctx.openDetailedOrderStatus(...args))),
      title: _ctx.$t('orders.card.status.more-info'),
      role: "button"
    }, [
      _createVNode(_component_StatusPill, {
        icon: "clipboard",
        "status-color": _ctx.statusModifier
      }, _createSlots({
        header: _withCtx(() => [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`orders.status.${_ctx.status}`)), 1),
          (_ctx.showPartly)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " | " + _toDisplayString(_ctx.$t(`orders.status.partly`)), 1))
            : _createCommentVNode("", true),
          (_ctx.status === 'DELIVERED' && !_ctx.isContainer)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                (_ctx.deliveryDate)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " " + _toDisplayString(_ctx.deliveryDateFormatted), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, " " + _toDisplayString(_ctx.eta), 1))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.showEta)
          ? {
              name: "main",
              fn: _withCtx(() => [
                (_ctx.showEta)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_7, _toDisplayString(_ctx.$t('orders.shipping.eta.eta')) + " " + _toDisplayString(_ctx.eta), 1))
                  : _createCommentVNode("", true),
                (_ctx.delay)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " " + _toDisplayString(_ctx.delay), 1))
                  : _createCommentVNode("", true)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["status-color"])
    ], 8, _hoisted_2),
    _createVNode(_component_OModal, {
      active: _ctx.detailActive,
      width: 640,
      cancelable: ['escape', 'outside']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("header", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, [
              _createVNode(_component_OIcon, {
                icon: "clipboard",
                class: "status__icon status__icon--clipboard"
              }),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('orders.card.status.label')), 1),
              _createElementVNode("span", {
                class: _normalizeClass(["status__value", `status__value--${_ctx.statusModifier}`])
              }, _toDisplayString(_ctx.$t(`orders.status.${_ctx.status}`)), 3)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "delete",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeDetailedOrderStatus && _ctx.closeDetailedOrderStatus(...args)))
            })
          ]),
          _createElementVNode("section", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t(`orders.status.more-info.${_ctx.status}`)), 1)
          ]),
          _createElementVNode("footer", _hoisted_15, [
            _createVNode(_component_OButton, {
              label: _ctx.$t('general.interface.back'),
              variant: "primary",
              rounded: "",
              onClick: _ctx.closeDetailedOrderStatus
            }, null, 8, ["label", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}