import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  "data-amplify-authenticator": "",
  "data-variation": "default"
}
const _hoisted_2 = { "data-amplify-container": "" }
const _hoisted_3 = {
  "data-amplify-router": "",
  "data-amplify-router-content": ""
}
const _hoisted_4 = {
  class: "amplify-flex amplify-authenticator__column",
  "data-amplify-fieldset": ""
}
const _hoisted_5 = {
  "data-amplify-heading": "",
  class: "amplify-heading amplify-heading--3"
}
const _hoisted_6 = { class: "amplify-flex amplify-authenticator__column" }
const _hoisted_7 = { class: "amplify-flex amplify-authenticator__column reset-password__footer" }
const _hoisted_8 = {
  key: 0,
  class: "amplify-flex amplify-alert amplify-alert--error amplify-authenticator__base",
  "data-variation": "error",
  role: "alert"
}
const _hoisted_9 = { class: "amplify-flex amplify-authenticator__icon-wrapper" }
const _hoisted_10 = {
  class: "amplify-button amplify-field-group__control amplify-button--primary amplify-field-group__control amplify-authenticator__font",
  "data-test": "submit",
  type: "submit",
  "data-fullwidth": "false",
  "data-variation": "primary",
  "data-loading": "false",
  "data-disabled": "false",
  fullwidth: "false",
  "data-amplify-button": ""
}

import BaseFieldText from '@/components/form/BaseFieldText.vue';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { ref, onMounted } from 'vue';
import { Form } from 'vee-validate';
import { useRouter, useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  setup(__props) {

const code = ref('');
const email = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const confirmResetPasswordErrorMessage = ref('');
const router = useRouter();
const route = useRoute();
const isEmailDisabled = ref(false);

onMounted((): void => {
  const emailQueryParam = route.query.email as string | undefined;
  if (emailQueryParam) {
    email.value = decodeURIComponent(emailQueryParam);
    isEmailDisabled.value = true;
  }
});

const onResendCode = async (): Promise<void> => {
  await resetPassword({
    username: email.value,
  });
};

const onSubmit = async (): Promise<void> => {
  try {
    await confirmResetPassword({
      username: email.value,
      confirmationCode: code.value,
      newPassword: newPassword.value,
    });

    await router.push({ name: 'authentication' });
  } catch (error: any) {
    confirmResetPasswordErrorMessage.value = error.message;
    isEmailDisabled.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["authenticator-custom reset-password", {
      'reset-password--error-message': confirmResetPasswordErrorMessage.value,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_unref(Form), {
              "data-amplify-form": "",
              "data-amplify-authenticator-confirmresetpassword": "",
              onSubmit: onSubmit
            }, {
              default: _withCtx(({ meta }) => [
                _createElementVNode("fieldset", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('amplify.Reset password')), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(BaseFieldText, {
                      "data-amplify-input": "",
                      "data-test": "email",
                      modelValue: email.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                      disabled: isEmailDisabled.value,
                      id: "email",
                      autofocus: "",
                      name: "email",
                      autocomplete: "email",
                      required: "",
                      placeholder: _ctx.$t('amplify.Email'),
                      type: "email",
                      "aria-invalid": "false",
                      autocapitalize: "off",
                      rules: "required|email",
                      label: _ctx.$t('amplify.Email')
                    }, null, 8, ["modelValue", "disabled", "placeholder", "label"]),
                    _createVNode(BaseFieldText, {
                      "data-amplify-input": "",
                      "data-test": "code",
                      modelValue: code.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((code).value = $event)),
                      id: "code",
                      autofocus: "",
                      "data-amplify-password": "true",
                      name: "confirmation_code",
                      autocomplete: "one-time-code",
                      required: "",
                      placeholder: _ctx.$t('amplify.Verification Code'),
                      type: "text",
                      "aria-invalid": "false",
                      autocapitalize: "off",
                      rules: "required",
                      label: _ctx.$t('amplify.Verification Code')
                    }, null, 8, ["modelValue", "placeholder", "label"]),
                    _createVNode(BaseFieldText, {
                      "data-amplify-input": "",
                      "data-test": "new-password",
                      modelValue: newPassword.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newPassword).value = $event)),
                      id: "new-password",
                      "data-amplify-password": "true",
                      name: "newPassword",
                      autocomplete: "new-password",
                      required: "",
                      placeholder: _ctx.$t('amplify.New Password'),
                      type: "password",
                      "aria-invalid": "false",
                      autocapitalize: "off",
                      passwordReveal: "",
                      rules: "required|password",
                      label: _ctx.$t('amplify.New Password')
                    }, null, 8, ["modelValue", "placeholder", "label"]),
                    _createVNode(BaseFieldText, {
                      "data-amplify-input": "",
                      "data-test": "confirm-password",
                      modelValue: confirmPassword.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((confirmPassword).value = $event)),
                      id: "confirm-password",
                      "data-amplify-password": "true",
                      name: "confirm_password",
                      autocomplete: "new-password",
                      required: "",
                      placeholder: _ctx.$t('amplify.Confirm password'),
                      type: "password",
                      "aria-invalid": "false",
                      autocapitalize: "off",
                      passwordReveal: "",
                      rules: "required|password|password-repeat:@newPassword",
                      label: _ctx.$t('amplify.Confirm password')
                    }, null, 8, ["modelValue", "placeholder", "label"])
                  ]),
                  _createElementVNode("footer", _hoisted_7, [
                    (confirmResetPasswordErrorMessage.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _cache[5] || (_cache[5] = _createElementVNode("svg", {
                              xmlns: "http://www.w3.org/2000/svg",
                              class: "amplify-icon",
                              "aria-hidden": "true",
                              viewBox: "0 0 24 24",
                              fill: "currentColor"
                            }, [
                              _createElementVNode("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" })
                            ], -1)),
                            _createElementVNode("div", null, _toDisplayString(confirmResetPasswordErrorMessage.value), 1)
                          ]),
                          _createElementVNode("button", {
                            class: "amplify-button amplify-field-group__control amplify-button--link amplify-field-group__control amplify-alert__dismiss",
                            type: "button",
                            "data-fullwidth": "false",
                            "data-variation": "link",
                            "data-loading": "false",
                            "data-disabled": "false",
                            "aria-label": "Dismiss alert",
                            fullwidth: "false",
                            "data-amplify-button": "",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (confirmResetPasswordErrorMessage.value = ''))
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("svg", {
                              xmlns: "http://www.w3.org/2000/svg",
                              class: "amplify-icon",
                              "aria-hidden": "true",
                              viewBox: "0 0 24 24",
                              fill: "currentColor"
                            }, [
                              _createElementVNode("path", { d: "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" })
                            ], -1)
                          ]))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t('amplify.Submit')), 1),
                    (meta.valid && confirmResetPasswordErrorMessage.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "amplify-button amplify-field-group__control amplify-button--link amplify-button--small amplify-field-group__control amplify-authenticator__font",
                          type: "button",
                          "data-fullwidth": "false",
                          "data-size": "small",
                          "data-variation": "link",
                          "data-loading": "false",
                          "data-disabled": "false",
                          fullwidth: "false",
                          "data-amplify-button": "",
                          onClick: onResendCode
                        }, _toDisplayString(_ctx.$t('amplify.Resend Code')), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ], 2))
}
}

})