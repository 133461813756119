import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "onboarding__wrapper" }
const _hoisted_2 = { class: "onboarding-authenticator__title" }
const _hoisted_3 = { class: "onboarding-authenticator__description" }
const _hoisted_4 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.checkCode()), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('onboarding.authenticator.qr-title')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('onboarding.authenticator.qr-description')), 1),
      _createVNode(_component_qrcode_vue, {
        class: "onboarding-authenticator__qr-code",
        value: _ctx.token,
        size: 220,
        level: "H"
      }, null, 8, ["value"]),
      _createVNode(_component_OField, null, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            modelValue: _ctx.code,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
            placeholder: 'Verify MFA'
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_OButton, {
        variant: "primary",
        rounded: "",
        disabled: _ctx.code.length !== 6,
        "native-type": "submit",
        class: "onboarding__button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.authenticator.finish')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 32))
}