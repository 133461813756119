<template>
  <form @submit.prevent="checkCode()">
    <div class="onboarding__wrapper">
      <h3 class="onboarding-authenticator__title">
        {{ $t('onboarding.authenticator.qr-title') }}
      </h3>
      <p class="onboarding-authenticator__description">
        {{ $t('onboarding.authenticator.qr-description') }}
      </p>
      <qrcode-vue
        class="onboarding-authenticator__qr-code"
        :value="token"
        :size="220"
        level="H"
      ></qrcode-vue>
      <OField>
        <OInput v-model="code" :placeholder="'Verify MFA'" />
      </OField>
    </div>
    <div class="button-row">
      <OButton
        variant="primary"
        rounded
        :disabled="code.length !== 6"
        native-type="submit"
        class="onboarding__button"
        >{{ $t('onboarding.authenticator.finish') }}</OButton
      >
    </div>
  </form>
</template>
<script lang="ts">
import QrcodeVue from 'qrcode.vue';
import {
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
} from 'aws-amplify/auth';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { defineComponent } from 'vue';
import { UpdateMFAPreferenceInput } from '@aws-amplify/auth';

export default defineComponent({
  components: {
    QrcodeVue,
  },
  mixins: [MessageToastMixin],
  data() {
    return {
      code: '',
      token: '',
    };
  },
  computed: {
    user(): any {
      return this.$store.state.auth.user;
    },
    username(): string | undefined {
      const username = this.user?.signInDetails?.loginId;
      return username ?? this.user.username;
    },
  },
  created() {
    this.setupToken();
  },
  methods: {
    async setupToken() {
      const totpSetupDetails = await setUpTOTP();
      const token = totpSetupDetails.getSetupUri(
        'Lamb Weston Portal',
        this.username,
      );

      this.token = token ? token.toString() : '';
    },
    async checkCode() {
      verifyTOTPSetup({ code: this.code })
        .then(() => {
          this.setMFA();
        })
        .catch((e: Error) => this.showError(e.message));
    },
    async setMFA() {
      const preference: UpdateMFAPreferenceInput = { totp: 'PREFERRED' };
      updateMFAPreference(preference)
        .then(() => {
          this.$store.dispatch('auth/refreshMFA');
          this.$emit('finished');
        })
        .catch((e: Error) => this.showError(e.message));
    },
  },
});
</script>
<style lang="scss" scoped>
.onboarding-authenticator {
  &__qr-code {
    text-align: center;
    margin: 32px;

    :deep(canvas) {
      width: 100% !important;
      height: auto !important;
      max-width: 220px;
    }
  }

  &__title {
    font-size: 18px;
  }

  &__description {
    font-size: 14px;
  }
}
</style>
