import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "hero__column hero__column--text" }
const _hoisted_2 = {
  key: 0,
  class: "hero__title"
}
const _hoisted_3 = {
  key: 1,
  class: "hero__title hero__title--subtitle"
}
const _hoisted_4 = { class: "hero__column hero__column--image-container" }
const _hoisted_5 = {
  key: 0,
  class: "hero__column hero__column--foreground-image"
}
const _hoisted_6 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["hero", 'hero--' + _ctx.pageName])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'hero__image-mask': _ctx.hasImageMask })
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.imageStyle),
          class: "hero__image"
        }, null, 4)
      ], 2)
    ]),
    (_ctx.pageName === 'eat-this')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "foreground-image",
            src: "/img/eat-this.svg",
            alt: _ctx.$t('navigation.eat-this')
          }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}