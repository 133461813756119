import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content-page" }
const _hoisted_2 = { class: "dashboard--content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Onboarding = _resolveComponent("Onboarding")!
  const _component_OModal = _resolveComponent("OModal")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_ContentBlocks = _resolveComponent("ContentBlocks")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!
  const _component_DashboardOrdersBlock = _resolveComponent("DashboardOrdersBlock")!
  const _component_DashboardInvoicesBlock = _resolveComponent("DashboardInvoicesBlock")!
  const _component_DashboardMultinationalSectionCta = _resolveComponent("DashboardMultinationalSectionCta")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_OModal, {
          active: _ctx.$store.getters['auth/showMFAOnboarding'],
          "trap-focus": "",
          cancelable: false,
          "aria-role": "dialog"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Onboarding)
          ]),
          _: 1
        }, 8, ["active"]),
        _createVNode(_component_Hero, {
          title: 
          _ctx.$t('home.title', {
            fullname: _ctx.fullName,
          })
        ,
          subtitle: 
          _ctx.customerName
            ? _ctx.$t('home.subtitle', { customerName: _ctx.customerName })
            : ''
        ,
          image: {
          formatUri: '/img/fries.{extension}',
          formatPreferredExtension: 'jpg',
          focusPointX: 2,
          focusPointY: 0,
        },
          gradient: true,
          pageName: 'dashboard'
        }, null, 8, ["title", "subtitle"]),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ContentBlocks, {
                key: 0,
                blocks: _ctx.blocks.filter((block) => block.type === 'news')
              }, null, 8, ["blocks"]))
            : (_openBlock(), _createBlock(_component_OSkeleton, {
                key: 1,
                animated: true,
                rootClass: "is-centered",
                width: "50%",
                height: "140px"
              })),
          (_ctx.hasMfa && (_ctx.hasAccessOrders || _ctx.hasAccessInvoices))
            ? (_openBlock(), _createElementBlock("div", {
                key: "statuses-blocks",
                class: _normalizeClass(["statuses layout__content", {
            'statuses--spacer': !_ctx.hasAccessOrders && !_ctx.hasAccessInvoices,
          }])
              }, [
                (_ctx.hasAccessOrders)
                  ? (_openBlock(), _createBlock(_component_DashboardOrdersBlock, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.hasAccessInvoices)
                  ? (_openBlock(), _createBlock(_component_DashboardInvoicesBlock, { key: 1 }))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.$store.getters['auth/memberOf']('multinationals'))
            ? (_openBlock(), _createBlock(_component_DashboardMultinationalSectionCta, {
                key: "multinational-blocks",
                class: "layout__content calltoaction-blocks calltoaction-blocks--multinational"
              }))
            : _createCommentVNode("", true),
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ContentBlocks, {
                key: "dashboardcalltoaction",
                class: "layout__content calltoaction-blocks",
                hasLayoutContent: false,
                blocks: 
            _ctx.blocks.filter((block) => block.type === 'dashboardcalltoaction')
          
              }, null, 8, ["blocks"]))
            : (_openBlock(), _createBlock(_component_OSkeleton, {
                key: "dashboardcalltoaction-loading",
                animated: true,
                rootClass: "is-centered",
                width: "50%",
                height: "340px",
                class: "skeleton--spacing"
              })),
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ContentBlocks, {
                key: "dashboardtext",
                blocks: _ctx.blocks.filter((block) => block.type === 'dashboardtext')
              }, null, 8, ["blocks"]))
            : (_openBlock(), _createBlock(_component_OSkeleton, {
                key: "dashboardtext-loading",
                animated: true,
                rootClass: "is-centered",
                width: "50%",
                height: "200px"
              }))
        ])
      ])
    ]),
    _: 1
  }))
}