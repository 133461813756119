import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "control is-clearfix" }
const _hoisted_2 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OField = _resolveComponent("OField")!

  return (_openBlock(), _createBlock(_component_OField, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          class: _normalizeClass(["input", { 'input--clamped': _ctx.clamped }]),
          type: "number",
          value: _ctx.value,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          step: "1",
          disabled: _ctx.$attrs.disabled
        }, null, 42, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}