import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout__content layout__content--large" }
const _hoisted_2 = { class: "claim-products" }
const _hoisted_3 = { class: "claim-products__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderItemList = _resolveComponent("OrderItemList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('claims.new.steps.summary.your-billback-claim')), 1),
      _createVNode(_component_OrderItemList, {
        items: _ctx.$store.state.claim.claimData.items,
        "sort-by-cart": "",
        type: "claim",
        agreement: _ctx.agreement,
        details: false
      }, null, 8, ["items", "agreement"])
    ])
  ]))
}