import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sidebar-container" }
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = { class: "top-bar__content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  "aria-role": "menu",
  class: "menu-list"
}
const _hoisted_7 = {
  key: 1,
  "aria-role": "menu",
  class: "menu-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ColorBox = _resolveComponent("ColorBox")!
  const _component_OMenuItem = _resolveComponent("OMenuItem")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OMenu = _resolveComponent("OMenu")!
  const _component_SapSwitcher = _resolveComponent("SapSwitcher")!
  const _component_OSidebar = _resolveComponent("OSidebar")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          to: "/",
          class: "sidebar__logo sidebar__logo--small"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.logo,
              alt: "Lamb Weston - Seeing Possibilities In Potatoes"
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu())),
          role: "button",
          "aria-label": "menu",
          class: _normalizeClass(["top-bar__burger navbar-burger burger", { 'is-active': _ctx.menuActive }])
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createTextVNode(),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 2)
      ]),
      _createVNode(_component_ColorBox)
    ]),
    _createVNode(_component_OSidebar, {
      class: _normalizeClass(["sidebar", 
        _ctx.menuActive
          ? 'sidebar--active'
          : '' || _ctx.isImpersonating
          ? 'sidebar--impersonating'
          : ''
      ]),
      mobile: "fullwidth",
      "mobile-breakpoint": `${_ctx.TABLET_BREAKPOINT.toString()}px`,
      fullheight: "",
      inline: "",
      fullwidth: false,
      active: _ctx.isWidescreen ? true : _ctx.menuActive,
      cancelable: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterLink, {
          to: "/",
          class: _normalizeClass(["sidebar__logo sidebar__logo--large", { 'sidebar__logo--multinational': !!_ctx.logo }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.logo,
              alt: "Lamb Weston - Seeing Possibilities In Potatoes"
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_OMenu, {
          id: "nav",
          class: "navigation"
        }, {
          default: _withCtx(() => [
            (!_ctx.hasAccess(undefined, _ctx.AuthGroup.Multinational))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.hasAccess({ action: 'PUT', resource: '/role' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 0,
                        icon: "keyhole",
                        label: _ctx.$t('navigation.permission-mgt'),
                        tag: "router-link",
                        to: { name: 'permission-mgt' },
                        class: _normalizeClass([{ 'is-active': _ctx.isActive('permission-mgt') }, "menu-item"])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/profiles' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 1,
                        icon: "user_management",
                        label: _ctx.$t('navigation.user-management'),
                        tag: "router-link",
                        to: "/user-management",
                        class: _normalizeClass([{ 'is-active': _ctx.isActive('/user-management') }, "menu-item"])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.signedIn)
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 2,
                        icon: "dashboard",
                        label: _ctx.$t('navigation.dashboard'),
                        tag: "router-link",
                        to: "/dashboard",
                        class: _normalizeClass([{ 'is-active': _ctx.isActive('/dashboard') }, "menu-item"])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/orders' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 3,
                        icon: 
              _ctx.$store.getters['profile/isContainer'] ? 'container_ship' : 'truck'
            ,
                        label: _ctx.$t('navigation.my-orders'),
                        tag: "router-link",
                        to: "/orders",
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/orders') }])
                      }, null, 8, ["icon", "label", "class"]))
                    : _createCommentVNode("", true),
                  (
              _ctx.hasAccess({ action: 'POST', resource: '/calculate-container' })
            )
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 4,
                        icon: "calculator",
                        label: _ctx.$t('navigation.container-calculation'),
                        tag: "router-link",
                        to: "/container-calculation",
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/container-calculation') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/invoices' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 5,
                        icon: "invoice",
                        label: _ctx.$t('navigation.my-invoices'),
                        tag: "router-link",
                        to: "/invoices",
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/invoices') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/contracts' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 6,
                        icon: "contracts",
                        label: _ctx.$t('navigation.my-volume-contracts'),
                        tag: "router-link",
                        to: { name: 'contracts' },
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/contracts') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/agreements' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 7,
                        icon: "contracts",
                        label: _ctx.$t('navigation.my-agreements'),
                        tag: "router-link",
                        to: { name: 'agreements' },
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/agreements') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/claims' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 8,
                        icon: "document-eye",
                        label: _ctx.$t('navigation.my-claims'),
                        tag: "router-link",
                        to: { name: 'claims' },
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/claims') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.signedIn)
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 9,
                        icon: "eat-this",
                        label: _ctx.$t('navigation.eat-this'),
                        tag: "router-link",
                        to: "/eat-this",
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/eat-this') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/products' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 10,
                        icon: "info_circle",
                        label: _ctx.$t('navigation.product-information'),
                        tag: "router-link",
                        to: { name: 'product-information' },
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/product-information') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasAccess({ action: 'GET', resource: '/dishes' }))
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 11,
                        icon: "flask",
                        expanded: _ctx.activeMenuExpansions.has('/menu-engineering'),
                        class: _normalizeClass(["menu-item menu-item--has-sub", {
              'is-active': _ctx.activeMenuExpansions.has('/menu-engineering'),
              'is-expanded': _ctx.activeMenuExpansions.has('/menu-engineering'),
            }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleActiveMenuExpansion('/menu-engineering')))
                      }, {
                        label: _withCtx(({ expanded }) => [
                          _createTextVNode(_toDisplayString(_ctx.$t('navigation.menu-engineering.main')) + " ", 1),
                          _createVNode(_component_OIcon, {
                            class: _normalizeClass(["is-pulled-right", { 'is-expanded': expanded }]),
                            icon: "chevron_up"
                          }, null, 8, ["class"])
                        ]),
                        default: _withCtx(() => [
                          (_ctx.hasAccess({ action: 'PUT', resource: '/dish' }))
                            ? (_openBlock(), _createBlock(_component_OMenuItem, {
                                key: 0,
                                icon: "add_circle",
                                label: _ctx.$t('navigation.menu-engineering.add-dish'),
                                tag: "router-link",
                                to: "/menu-engineering/add-dish",
                                class: _normalizeClass([{ 'is-active': _ctx.isActive('/menu-engineering/add-dish') }, "menu-item menu-item--sub"])
                              }, null, 8, ["label", "class"]))
                            : _createCommentVNode("", true),
                          (_ctx.hasAccess({ action: 'GET', resource: '/dishes' }))
                            ? (_openBlock(), _createBlock(_component_OMenuItem, {
                                key: 1,
                                icon: "my_results",
                                label: _ctx.$t('navigation.menu-engineering.my-results'),
                                tag: "router-link",
                                to: "/menu-engineering/results",
                                class: _normalizeClass([{ 'is-active': _ctx.isActive('/menu-engineering/results') }, "menu-item menu-item--sub"])
                              }, null, 8, ["label", "class"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["expanded", "class"]))
                    : _createCommentVNode("", true),
                  (
              _ctx.hasAccess({
                action: 'GET',
                resource: `/content/customer_portal/{locale}/e-learning`,
              })
            )
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 12,
                        icon: "e-learning",
                        label: _ctx.$t('navigation.e-learning'),
                        tag: "router-link",
                        to: { name: 'e-learning' },
                        class: _normalizeClass(["menu-item", { 'is-active': _ctx.isExpanded('/e-learning') }])
                      }, null, 8, ["label", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.signedIn)
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 13,
                        icon: "settings",
                        class: _normalizeClass(["menu-item menu-item--has-sub", {
              'is-active': _ctx.activeMenuExpansions.has('/settings'),
              'is-expanded': _ctx.activeMenuExpansions.has('/settings'),
            }]),
                        expanded: _ctx.activeMenuExpansions.has('/settings'),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleActiveMenuExpansion('/settings')))
                      }, {
                        label: _withCtx(({ expanded }) => [
                          _createTextVNode(_toDisplayString(_ctx.$t('navigation.settings.main')) + " ", 1),
                          _createVNode(_component_OIcon, {
                            class: _normalizeClass(["is-pulled-right", { 'is-expanded': expanded }]),
                            icon: "chevron_up"
                          }, null, 8, ["class"])
                        ]),
                        default: _withCtx(() => [
                          (_ctx.hasAccess({ action: 'GET', resource: '/profile' }))
                            ? (_openBlock(), _createBlock(_component_OMenuItem, {
                                key: 0,
                                icon: "profile",
                                label: _ctx.$t('navigation.settings.my-profile'),
                                tag: "router-link",
                                to: "/settings/profile",
                                class: _normalizeClass(["menu-item menu-item--sub", { 'is-active': _ctx.isActive('/settings/profile') }])
                              }, null, 8, ["label", "class"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["expanded", "class"]))
                    : _createCommentVNode("", true),
                  (_ctx.signedIn)
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 14,
                        icon: "sign_out",
                        label: _ctx.$t('navigation.sign-out'),
                        class: "menu-item",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.signOut()))
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (
                _ctx.hasAccess({
                  action: 'GET',
                  resource: _ctx.multinationalSlug(item),
                })
              )
                        ? (_openBlock(), _createBlock(_component_OMenuItem, {
                            key: item.route,
                            icon: item.icon,
                            label: _ctx.renderMenuItemLabel(item),
                            tag: "router-link",
                            to: { name: item.route },
                            active: _ctx.isActive(item.route),
                            class: _normalizeClass(["menu-item", { 'is-active': _ctx.isActive(item.route) }])
                          }, null, 8, ["icon", "label", "to", "active", "class"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256)),
                  (_ctx.signedIn)
                    ? (_openBlock(), _createBlock(_component_OMenuItem, {
                        key: 0,
                        icon: "sign_out",
                        label: _ctx.$t('navigation.sign-out'),
                        class: "menu-item",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.signOut()))
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        }),
        _createVNode(_component_SapSwitcher, { class: "sap-switcher" })
      ]),
      _: 1
    }, 8, ["class", "mobile-breakpoint", "active"]),
    _createVNode(_component_OLoading, {
      active: _ctx.loading,
      "full-page": true
    }, null, 8, ["active"])
  ]))
}