import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider" }
const _hoisted_2 = { class: "slider__upper" }
const _hoisted_3 = { class: "slider__label" }
const _hoisted_4 = { class: "slider__label-text" }
const _hoisted_5 = { class: "slider__lower" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OTooltip = _resolveComponent("OTooltip")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OSliderTick = _resolveComponent("OSliderTick")!
  const _component_OSlider = _resolveComponent("OSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1),
        (_ctx.tooltip)
          ? (_openBlock(), _createBlock(_component_OTooltip, {
              key: 0,
              label: _ctx.tooltip
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("img", {
                  src: `/img/icons/info.svg`,
                  alt: 'More information',
                  class: "slider__info"
                }, null, -1)
              ])),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_OIcon, { icon: "info" })
      ]),
      _createVNode(_component_OInput, {
        size: "small",
        type: "number",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.input($event))),
        min: _ctx.min,
        max: _ctx.max,
        pattern: "\\d+",
        "root-class": "slider__input"
      }, null, 8, ["modelValue", "min", "max"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_OIcon, {
        onClick: _ctx.subtract,
        class: "slider__button slider__button--subtract",
        variant: "white",
        icon: "minus"
      }, null, 8, ["onClick"]),
      _createVNode(_component_OSlider, {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.input($event))),
        modelValue: _ctx.value,
        rounded: "",
        min: _ctx.min,
        max: _ctx.max,
        "bigger-slider-focus": "",
        variant: "secondary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OSliderTick, { value: _ctx.min }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.min), 1)
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_OSliderTick, { value: _ctx.max }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.max), 1)
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["modelValue", "min", "max"]),
      _createVNode(_component_OIcon, {
        onClick: _ctx.add,
        class: "slider__button slider__button--add",
        variant: "white",
        icon: "plus"
      }, null, 8, ["onClick"])
    ])
  ]))
}