import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "category-header" }
const _hoisted_2 = { class: "category-header__icon" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "category-header__attributes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: _normalizeClass(`category-header__icon--${_ctx.score.toLowerCase()} category-header__icon--chef`),
        src: `/img/me/chef_${_ctx.score.toLowerCase()}.svg`,
        alt: _ctx.$t('menuEngineering.imageAlt.icon')
      }, null, 10, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attribute, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass(`category-header__attribute category-header__attribute--${attribute.score}`)
        }, _toDisplayString(attribute.text), 3))
      }), 128))
    ])
  ]))
}