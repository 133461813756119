import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-bar" }
const _hoisted_2 = { class: "search-bar__content layout__content layout__content--large" }
const _hoisted_3 = {
  key: 0,
  class: "search-bar__top"
}
const _hoisted_4 = { class: "search-bar__top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductWarehouseFilter = _resolveComponent("ProductWarehouseFilter")!
  const _component_ProductGroupFilter = _resolveComponent("ProductGroupFilter")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_ProductSearchField = _resolveComponent("ProductSearchField")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$store.getters['order/warehouseCount'] > 1 && _ctx.type === 'order')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ProductWarehouseFilter)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ProductGroupFilter, { type: _ctx.type }, null, 8, ["type"]),
        _createElementVNode("div", {
          class: _normalizeClass(["search-bar__toggle-icon", { 'search-bar__toggle-icon--active': _ctx.active }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSearchBar && _ctx.toggleSearchBar(...args)))
        }, [
          _createVNode(_component_OIcon, {
            icon: _ctx.active ? 'close' : 'search',
            size: "small"
          }, null, 8, ["icon"])
        ], 2)
      ]),
      (_ctx.active)
        ? (_openBlock(), _createBlock(_component_ProductSearchField, {
            key: 1,
            type: _ctx.type
          }, null, 8, ["type"]))
        : _createCommentVNode("", true)
    ])
  ]))
}