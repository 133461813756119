import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseMessage from '@/components/base/BaseMessage.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MfaWarning',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(BaseMessage, {
    class: "mfa-warning",
    variant: "warning",
    closable: false
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('authentication.warning.requires-mfa')) + " ", 1),
      _createVNode(_component_RouterLink, { to: `/settings/profile` }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('navigation.settings.my-profile')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})