import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "claim-details-form" }
const _hoisted_2 = { class: "claim-details-form__grid layout__content layout__content--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_BaseFieldText = _resolveComponent("BaseFieldText")!
  const _component_BaseFieldDateRange = _resolveComponent("BaseFieldDateRange")!
  const _component_NewClaimYearSelector = _resolveComponent("NewClaimYearSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader, { "check-mfa": true }, {
      intro: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('claims.new.steps.details-form.intro')), 1)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" 1.1 " + _toDisplayString(_ctx.$t('claims.new.steps.details-form.header')) + " ", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseFieldText, {
        name: "wholesaleParty",
        class: "claim-details-form__field claim-details-form__field--wholesale-party",
        value: _ctx.$store.state.claim.claimData.wholesaleParty,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.$store.commit('claim/setNewClaimWholesaleParty', $event)
        )),
        label: _ctx.$t('claims.new.steps.details-form.wholesale-party.label'),
        placeholder: 
          _ctx.$t('claims.new.steps.details-form.wholesale-party.placeholder')
        ,
        rules: "required"
      }, null, 8, ["value", "label", "placeholder"]),
      (_ctx.showMonthSelector)
        ? (_openBlock(), _createBlock(_component_BaseFieldDateRange, {
            key: 0,
            name: "claimPeriodMonth",
            class: "claim-details-form__field claim-details-form__field--period",
            value: _ctx.$store.state.claim.claimData.period,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('claim/setNewClaimPeriod', $event))),
            label: _ctx.$t('claims.new.steps.details-form.period.label'),
            placeholder: _ctx.$t('claims.new.steps.details-form.period.placeholder'),
            rules: _ctx.dateRangeFormRules,
            "min-date": _ctx.minDate || undefined,
            "max-date": _ctx.maxDate,
            "data-test": "claim-period",
            disabled: _ctx.isAnnual
          }, null, 8, ["value", "label", "placeholder", "rules", "min-date", "max-date", "disabled"]))
        : (_openBlock(), _createBlock(_component_NewClaimYearSelector, {
            key: 1,
            name: "claimPeriodYear",
            "begin-date": _ctx.minDate,
            "end-date": _ctx.maxDate,
            class: "claim-details-form__field claim-details-form__field--period",
            label: _ctx.$t('claims.new.steps.details-form.period.label'),
            placeholder: _ctx.$t('claims.new.steps.details-form.period.placeholder'),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('claim/setNewClaimPeriod', $event))),
            "data-test": "claim-period",
            rules: "required",
            modelValue: _ctx.$store.state.claim.claimData.period
          }, null, 8, ["begin-date", "end-date", "label", "placeholder", "modelValue"])),
      _createVNode(_component_BaseFieldText, {
        name: "creditReference",
        class: "claim-details-form__field claim-details-form__field--credit-reference",
        value: _ctx.$store.state.claim.claimData.creditReference,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
          _ctx.$store.commit('claim/setNewClaimCreditReference', $event)
        )),
        label: _ctx.$t('claims.new.steps.details-form.credit-reference.label'),
        placeholder: 
          _ctx.$t('claims.new.steps.details-form.credit-reference.placeholder')
        
      }, null, 8, ["value", "label", "placeholder"])
    ])
  ]))
}