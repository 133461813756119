import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = ["label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ODatepicker = _resolveComponent("ODatepicker")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OField = _resolveComponent("OField")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules
  }, {
    default: _withCtx(({ errors, field }) => [
      _createVNode(_component_OField, _mergeProps({
        label: _ctx.$attrs.label,
        message: errors.length ? errors[0] : '',
        "root-class": "date-range"
      }, _ctx.$attrs, {
        class: _ctx.classNames(errors),
        variant: errors.length ? 'danger' : '',
        horizontal: _ctx.$attrs.horizontal
      }), {
        default: _withCtx(() => [
          _createVNode(_component_ODatepicker, _mergeProps(field, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _ctx.onInput,
            placeholder: _ctx.$attrs.placeholder,
            disabled: _ctx.$attrs.disabled,
            expanded: "",
            icon: "calendar",
            "icon-prev": "chevron_left",
            "icon-next": "chevron_right",
            type: "month",
            "trap-focus": "",
            range: "",
            "min-date": _ctx.minDate,
            "max-date": _ctx.maxDate,
            "focused-date": _ctx.minDate,
            "close-on-click": true,
            "date-formatter": _ctx.dateFormatter,
            onRangeStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rangeStart($event)))
          }), {
            footer: _withCtx(() => [
              _createElementVNode("a", {
                role: "button",
                label: _ctx.$t('claims.new.steps.details-form.period.clear'),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
                class: "date-range__clear"
              }, _toDisplayString(_ctx.$t('claims.new.steps.details-form.period.clear')), 9, _hoisted_1)
            ]),
            _: 2
          }, 1040, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "min-date", "max-date", "focused-date", "date-formatter"]),
          (!_ctx.$attrs.disabled)
            ? (_openBlock(), _createBlock(_Transition, {
                key: 0,
                name: "fade"
              }, {
                default: _withCtx(() => [
                  (_ctx.value && _ctx.value.length)
                    ? (_openBlock(), _createBlock(_component_OIcon, {
                        key: 0,
                        class: "date-range__clear-icon",
                        icon: "close_circle",
                        role: "button",
                        onClick: _ctx.clear
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1040, ["label", "message", "class", "variant", "horizontal"])
    ]),
    _: 1
  }, 8, ["name", "rules"]))
}