import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileForm = _resolveComponent("ProfileForm")!
  const _component_ProfileRestaurants = _resolveComponent("ProfileRestaurants")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ProfileForm),
        (_ctx.canUseMenuEngineering)
          ? (_openBlock(), _createBlock(_component_ProfileRestaurants, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}