import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "category__icon" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "category__text" }
const _hoisted_4 = { class: "category__title" }
const _hoisted_5 = { class: "category__amount" }
const _hoisted_6 = { class: "category__attributes" }
const _hoisted_7 = { class: "category__attribute" }
const _hoisted_8 = { class: "category__attribute" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.label)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: '/menu-engineering/category/' + _ctx.label + 's',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setRestaurantId())),
        class: "category"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: `/img/me/chef_${_ctx.label}.svg`,
              alt: _ctx.$t('menuEngineering.imageAlt.' + _ctx.label)
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`menuEngineering.label.${_ctx.label}s`)), 1),
            _createElementVNode("span", _hoisted_5, " (" + _toDisplayString(_ctx.dishCount) + ") ", 1),
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t(`menuEngineering.label.${_ctx.label}.profit`)), 1),
              _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.$t(`menuEngineering.label.${_ctx.label}.demand`)), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}