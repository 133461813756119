import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-dialog" }
const _hoisted_2 = { class: "base-dialog__header" }
const _hoisted_3 = { class: "base-dialog__title" }
const _hoisted_4 = { class: "base-dialog__message" }
const _hoisted_5 = { class: "base-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_OIcon, {
            key: 0,
            class: "base-dialog__icon",
            icon: _ctx.icon,
            variant: "danger"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
      (_ctx.inputField)
        ? (_openBlock(), _createBlock(_component_OInput, _mergeProps({
            key: 1,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.modelValue = $event))
          }, _ctx.mergedInputAttrs), null, 16))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("footer", _hoisted_5, [
      (_ctx.cancelText !== '')
        ? (_openBlock(), _createBlock(_component_OButton, {
            key: 0,
            rounded: "",
            outlined: "",
            rootClass: "base-dialog__button",
            onClick: _cache[1] || (_cache[1] = 
          () => {
            _ctx.$emit('close');
            _ctx.onCancel();
          }
        ),
            variant: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.confirmText !== '')
        ? (_openBlock(), _createBlock(_component_OButton, {
            key: 1,
            rounded: "",
            rootClass: "base-dialog__button",
            onClick: _ctx.handleInputConfirm,
            variant: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}