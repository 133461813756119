import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "update-button"
}
const _hoisted_2 = { class: "update-button--button-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OIcon, {
          class: "update-button--icon",
          icon: "info_circle",
          variant: "secondary"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t(`updates-modal.scopes.${_ctx.scope}.button-text`)) + " ", 1),
          _createVNode(_component_OButton, {
            class: "update-button--button",
            onClick: _ctx.openUpdateModal,
            size: "small"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`updates-modal.scopes.${_ctx.scope}.read-more`)), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}