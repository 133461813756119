import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "eat-this-download__column"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "eat-this-download__column" }
const _hoisted_5 = {
  key: 0,
  class: "title title--h2 block__title"
}
const _hoisted_6 = {
  key: 1,
  class: "eat-this-download__text"
}
const _hoisted_7 = {
  key: 2,
  class: "eat-this-download__link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.block.file && _ctx.block.file.url)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "block eat-this-download form-card",
        href: _ctx.$helpers.getFileUrl(_ctx.block.file.url),
        target: "_blank",
        rel: "noopener"
      }, [
        (_ctx.block.image)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "eat-this-download__image",
                src: _ctx.$helpers.getApiImageThumbnail(_ctx.block.image),
                alt: "eat-this file thumbnail"
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.block.title)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.block.title), 1))
            : _createCommentVNode("", true),
          (_ctx.block.text)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.block.text), 1))
            : _createCommentVNode("", true),
          (_ctx.block.file)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.$t('eatThis.download')) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  class: "eat-this-download__arrow",
                  src: "/img/icons/arrow.svg",
                  alt: "arrow"
                }, null, -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}