<template>
  <section>
    <p class="onboarding__wrapper">{{ $t('onboarding.none.description') }}</p>
    <div class="button-row">
      <OButton variant="primary" rounded @click="disableMFA()">{{
        $t('onboarding.none.finish')
      }}</OButton>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  updateMFAPreference,
  UpdateMFAPreferenceInput,
} from 'aws-amplify/auth';

export default defineComponent({
  components: {},
  methods: {
    disableMFA() {
      const mfaPreference: UpdateMFAPreferenceInput = {
        sms: 'DISABLED',
        totp: 'DISABLED',
      };

      updateMFAPreference(mfaPreference).then(() => {
        this.$store.dispatch('auth/refreshMFA');
        this.$emit('close');
      });
      this.$store.dispatch('auth/disableMFAOnboarding');
    },
  },
});
</script>
