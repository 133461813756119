import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block__top" }
const _hoisted_2 = { class: "block__header" }
const _hoisted_3 = { class: "block__title" }
const _hoisted_4 = {
  key: 0,
  class: "block__details"
}
const _hoisted_5 = { class: "block__details-column" }
const _hoisted_6 = { class: "block__subtitle" }
const _hoisted_7 = { class: "block__text" }
const _hoisted_8 = { class: "block__details-column" }
const _hoisted_9 = { class: "block__subtitle" }
const _hoisted_10 = { class: "block__text" }
const _hoisted_11 = {
  key: 1,
  class: "block__details"
}
const _hoisted_12 = {
  key: 2,
  class: "block__separator-wrapper"
}
const _hoisted_13 = {
  key: 3,
  class: "block__details"
}
const _hoisted_14 = { class: "block__details-column" }
const _hoisted_15 = { class: "block__subtitle" }
const _hoisted_16 = { class: "block__text" }
const _hoisted_17 = { class: "block__bottom" }
const _hoisted_18 = { class: "block__title" }
const _hoisted_19 = { class: "block__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!

  return (_ctx.isVisibleToUser() && _ctx.active)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: "block block--status block--orders",
        to: { name: 'orders' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_OIcon, {
                icon: "truck",
                class: "block__icon"
              }),
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('dashboard.orders.your-orders-deliveries')), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "block__separator-wrapper" }, [
              _createElementVNode("div", { class: "block__separator" })
            ], -1)),
            (this.lastOrder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('dashboard.orders.last-order')) + ": ", 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(this.lastOrder.OrderNumber), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('dashboard.orders.order-date')) + ": ", 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.lastOrderDate), 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('dashboard.orders.no-last-order')), 1)),
            (this.lastOrder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[0] || (_cache[0] = [
                  _createElementVNode("div", { class: "block__separator" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (this.lastOrder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t('dashboard.orders.order-status')) + ": ", 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(`orders.status.${this.lastOrder.OrderStatus}`)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("h2", _hoisted_18, _toDisplayString(_ctx.$t('dashboard.orders.my-orders-deliveries')), 1),
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('dashboard.orders.go-to-orders-overview')), 1),
            _createVNode(_component_OIcon, {
              icon: "arrow_back",
              class: "block__icon"
            })
          ])
        ]),
        _: 1
      }))
    : (_ctx.isVisibleToUser())
      ? (_openBlock(), _createBlock(_component_OSkeleton, {
          key: 1,
          animated: true,
          width: "96%",
          height: "320px"
        }))
      : _createCommentVNode("", true)
}