import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "claim-details" }
const _hoisted_2 = { class: "layout__content layout__content--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_ClaimDetailsCard = _resolveComponent("ClaimDetailsCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader, { "check-mfa": true }, {
      default: _withCtx(() => [
        _createTextVNode(" 2.2 " + _toDisplayString(_ctx.$t('claims.new.steps.details.header')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.agreement)
        ? (_openBlock(), _createBlock(_component_ClaimDetailsCard, {
            key: 0,
            claim: { wholesale_party: _ctx.claim.wholesaleParty },
            agreement: _ctx.agreement,
            class: "claim-details__card"
          }, null, 8, ["claim", "agreement"]))
        : _createCommentVNode("", true)
    ])
  ]))
}