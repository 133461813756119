import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "authentication" }
const _hoisted_2 = { class: "authentication__topbar" }
const _hoisted_3 = { key: "not-signed-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkCorporate = _resolveComponent("LinkCorporate")!
  const _component_SwitchLocale = _resolveComponent("SwitchLocale")!
  const _component_ResetPassword = _resolveComponent("ResetPassword")!
  const _component_AuthenticatorCustom = _resolveComponent("AuthenticatorCustom")!
  const _component_LayoutAuthentication = _resolveComponent("LayoutAuthentication")!

  return (_openBlock(), _createBlock(_component_LayoutAuthentication, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "authentication__bg" }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LinkCorporate),
          _createVNode(_component_SwitchLocale)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.$route.name === 'passwordreset')
            ? (_openBlock(), _createBlock(_component_ResetPassword, { key: 0 }))
            : (_openBlock(), _createBlock(_component_AuthenticatorCustom, {
                key: `amp-${_ctx.$i18n.locale}`,
                class: "authentication__authenticator"
              }))
        ])
      ])
    ]),
    _: 1
  }))
}