import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "claim-overview__products" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_NewClaimOverviewProducts = _resolveComponent("NewClaimOverviewProducts")!
  const _component_NewClaimOverviewDetails = _resolveComponent("NewClaimOverviewDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_BaseHeader, { "check-mfa": true }, {
        default: _withCtx(() => [
          _createTextVNode(" 2.1 " + _toDisplayString(_ctx.$t('claims.new.steps.summary.header')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_NewClaimOverviewProducts)
    ]),
    _createVNode(_component_NewClaimOverviewDetails)
  ]))
}