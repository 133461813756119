import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer-bar" }
const _hoisted_2 = { class: "footer-bar__content" }
const _hoisted_3 = { class: "footer-bar__copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('footer.copyright', { year: _ctx.year })), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (link) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: link.to,
          to: link.to,
          target: link.target || '_self',
          class: "footer-bar__nav-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(link.label)), 1)
          ]),
          _: 2
        }, 1032, ["to", "target"]))
      }), 128))
    ])
  ]))
}