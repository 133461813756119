import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "invoice-status__label" }
const _hoisted_2 = {
  key: 0,
  class: "invoice-status__days"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["invoice-status", _ctx.modifier])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.status), 1),
    (_ctx.showDays)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.$t('invoices.status.days-till-due-date', _ctx.invoice.DaysTillDueDate)) + ") ", 1))
      : _createCommentVNode("", true)
  ], 2))
}