import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "download"
}
const _hoisted_2 = { class: "download__label" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_ctx.hasDownload)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          class: "download__link",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)), ["prevent"]))
        }, [
          _createVNode(_component_OIcon, {
            class: "download__icon",
            icon: "download"
          }),
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _createElementVNode("a", {
          ref: "download-link",
          class: "download__hidden",
          download: "",
          href: _ctx.s3Url
        }, null, 8, _hoisted_3)
      ]))
    : _createCommentVNode("", true)
}