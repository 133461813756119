import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = {
  key: 0,
  class: "title title--h2 block__title"
}
const _hoisted_3 = {
  key: 1,
  class: "block__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_ctx.link && _ctx.isVisibleToUser())
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "block form-card",
        href: _ctx.link,
        target: _ctx.linkTarget
      }, [
        (_ctx.block.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.block.title), 1))
          : _createCommentVNode("", true),
        (_ctx.block.text)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.block.text), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_OIcon, {
          icon: "arrow_back",
          class: "block__icon"
        })
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}