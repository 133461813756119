import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "select-products" }
const _hoisted_2 = { class: "layout__header layout__content layout__content--large" }
const _hoisted_3 = { class: "select-products__title" }
const _hoisted_4 = { class: "title title--direct" }
const _hoisted_5 = { class: "layout__intro-text layout__intro-text--orders" }
const _hoisted_6 = {
  key: 0,
  class: "select-products__preferred-warehouse-warning layout__content layout__content--large"
}
const _hoisted_7 = { class: "select-products__preferred-warehouse-warning__content" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "select-products__pre-table-header select-products__pre-table-header--mobile layout__content" }
const _hoisted_10 = { class: "select-products__table" }
const _hoisted_11 = { class: "layout__content layout__content--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateButton = _resolveComponent("UpdateButton")!
  const _component_ProductSearchBar = _resolveComponent("ProductSearchBar")!
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OTooltip = _resolveComponent("OTooltip")!
  const _component_CustomLabelSwitch = _resolveComponent("CustomLabelSwitch")!
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_o_table = _resolveComponent("o-table")!
  const _component_SelectProductGroup = _resolveComponent("SelectProductGroup")!
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_NewOrderFooter = _resolveComponent("NewOrderFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('orders.new.title')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('orders.new.intro')), 1)
      ]),
      _createVNode(_component_UpdateButton, {
        scope: "new-product-cold-store",
        form: "2024-07-01",
        till: "2024-09-30",
        "promoted-till": "2024-09-15"
      })
    ]),
    _createVNode(_component_ProductSearchBar, { class: "select-products__search" }),
    (
        _ctx.$store.getters['order/activePreferredWarehouse'] !== '' &&
        _ctx.$store.getters['order/warehouseCount'] > 1
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_o_icon, {
              icon: "info_circle",
              type: 'is-secondary',
              class: "select-products__preferred-warehouse-warning__icon"
            }),
            _createElementVNode("p", {
              class: "select-products__preferred-warehouse-warning__text",
              innerHTML: _ctx.preferredWarehouseMessage
            }, null, 8, _hoisted_8)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createTextVNode(_toDisplayString(_ctx.$t('orders.order-item-list.header.products')) + " ", 1),
      _createVNode(_component_OTooltip, {
        label: _ctx.$t('orders.new.help-text'),
        variant: "light",
        multiline: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OIcon, {
            icon: "info_circle",
            variant: "primary"
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_CustomLabelSwitch, {
        class: "select-products__custom-label-switch",
        onChanged: _ctx.handleLabelChange,
        positionLeft: true
      }, null, 8, ["onChanged"])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("a", { id: "results" }, null, -1)),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_o_table, {
          data: [],
          "root-class": "product-item-list product-item-list--no-body"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.product')
            }, {
              header: _withCtx(({ column }) => [
                _createTextVNode(_toDisplayString(column.label) + " ", 1),
                _createVNode(_component_OTooltip, {
                  label: _ctx.$t('orders.new.help-text'),
                  variant: "light",
                  multiline: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_OIcon, {
                      icon: "info_circle",
                      variant: "primary"
                    })
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_CustomLabelSwitch, {
                  class: "select-products__custom-label-switch",
                  onChanged: _ctx.handleLabelChange
                }, null, 8, ["onChanged"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.code'),
              width: 110
            }, null, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.warehouse'),
              width: 120
            }, null, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.art'),
              width: 100
            }, null, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.pallets'),
              width: 90,
              "root-class": "is-centered"
            }, null, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.layers'),
              width: 90,
              "root-class": "is-centered"
            }, null, 8, ["label"]),
            _createVNode(_component_o_table_column, {
              label: _ctx.$t('orders.order-item-list.header.boxes'),
              width: 80,
              "root-class": "is-centered"
            }, null, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupLabels, (groupLabel) => {
        return (_openBlock(), _createBlock(_component_SelectProductGroup, {
          key: groupLabel,
          "group-label": groupLabel,
          products: _ctx.groups[groupLabel],
          "custom-text": !_ctx.LWText,
          onChange: _ctx.handleCartChanged
        }, null, 8, ["group-label", "products", "custom-text", "onChange"]))
      }), 128)),
      _createVNode(_component_OLoading, {
        active: _ctx.loading,
        "full-page": false,
        cancelable: ""
      }, null, 8, ["active"])
    ]),
    _createVNode(_component_NewOrderFooter, { step: "select-products" })
  ]))
}