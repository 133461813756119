import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, TransitionGroup as _TransitionGroup, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dish-list layout__content"
}
const _hoisted_2 = {
  key: 0,
  class: "dish-list__arrow"
}
const _hoisted_3 = {
  key: 2,
  class: "dish-list__title layout__content layout__content--small subtitle"
}
const _hoisted_4 = { class: "dish__name" }
const _hoisted_5 = { class: "dish__actions" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_CategoryHeader = _resolveComponent("CategoryHeader")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.dishes.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.showHeader)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_OIcon, { icon: "chevron_up" })
                ]))
              : _createCommentVNode("", true),
            (_ctx.showHeader)
              ? (_openBlock(), _createBlock(_component_CategoryHeader, {
                  key: 1,
                  score: _ctx.score
                }, null, 8, ["score"]))
              : _createCommentVNode("", true),
            (_ctx.showTitle && _ctx.restaurantName)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.$t('menuEngineering.label.course.' + _ctx.course)) + " " + _toDisplayString(_ctx.$t('general.for')) + " " + _toDisplayString(_ctx.restaurantName) + " (" + _toDisplayString(_ctx.dishes.length) + ") ", 1))
              : _createCommentVNode("", true),
            _createVNode(_TransitionGroup, {
              name: "list",
              tag: "div",
              class: "dish-list__list"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishes, (dish) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: dish.id,
                    class: "dish list-item"
                  }, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(dish.name), 1),
                    _createElementVNode("span", _hoisted_5, [
                      _createVNode(_component_RouterLink, {
                        to: `/menu-engineering/edit-dish/${dish.id}`,
                        class: "dish__action dish__action--edit action-button"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, [
                            _createVNode(_component_OIcon, {
                              class: "action-button__icon",
                              icon: "edit"
                            })
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      (
                _ctx.$store.getters['auth/hasAccess']({
                  action: 'DELETE',
                  resource: '/dish',
                })
              )
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "dish__action dish__action--delete action-button",
                            onClick: ($event: any) => (_ctx.deleteDish(dish))
                          }, [
                            _createVNode(_component_OIcon, {
                              class: "action-button__icon",
                              icon: "delete"
                            })
                          ], 8, _hoisted_6))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}