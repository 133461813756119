import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "modal-card modal-card--small-content" }
const _hoisted_2 = { class: "modal-card-body" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "title title--h1 onboarding__title" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "onboarding__current" }
const _hoisted_7 = { class: "onboarding__current-selection" }
const _hoisted_8 = { class: "onboarding__current-title" }
const _hoisted_9 = { class: "onboarding__current-description" }
const _hoisted_10 = { class: "onboarding__current-change" }
const _hoisted_11 = { class: "onboarding__step-wrapper" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "onboarding__steps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingFinished = _resolveComponent("OnboardingFinished")!
  const _component_OnboardingWelcome = _resolveComponent("OnboardingWelcome")!
  const _component_OnboardingAuthenticator = _resolveComponent("OnboardingAuthenticator")!
  const _component_OnboardingSms = _resolveComponent("OnboardingSms")!
  const _component_OnboardingNoMFA = _resolveComponent("OnboardingNoMFA")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_ctx.finished)
        ? (_openBlock(), _createBlock(_component_OnboardingFinished, {
            key: 0,
            MFAType: _ctx.MFAType
          }, null, 8, ["MFAType"]))
        : (_ctx.isActiveType(''))
          ? (_openBlock(), _createBlock(_component_OnboardingWelcome, {
              key: 1,
              MFATypes: _ctx.MFATypes,
              onChoice: _ctx.chooseMFAType
            }, null, 8, ["MFATypes", "onChoice"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t(`onboarding.${_ctx.MFAType}.title`)), 1),
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('onboarding.mfa-preference')), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(`onboarding.welcome.buttons.${_ctx.MFAType}.type`)), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t(`onboarding.welcome.buttons.${_ctx.MFAType}.label`)), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.MFAType = ''))
                  }, _toDisplayString(_ctx.$t('onboarding.change-mfa')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.$te(`onboarding.${_ctx.MFAType}.step-title`))
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_12, _toDisplayString(_ctx.$t(`onboarding.${_ctx.MFAType}.step-title`)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("ul", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MFATypeSteps, (step) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "onboarding__step",
                      key: step
                    }, _toDisplayString(step), 1))
                  }), 128))
                ])
              ]),
              (_ctx.isActiveType('authenticator'))
                ? (_openBlock(), _createBlock(_component_OnboardingAuthenticator, {
                    key: 0,
                    onFinished: _ctx.setFinished
                  }, null, 8, ["onFinished"]))
                : _createCommentVNode("", true),
              (_ctx.isActiveType('sms'))
                ? (_openBlock(), _createBlock(_component_OnboardingSms, {
                    key: 1,
                    onFinished: _ctx.setFinished
                  }, null, 8, ["onFinished"]))
                : _createCommentVNode("", true),
              (_ctx.isActiveType('none'))
                ? (_openBlock(), _createBlock(_component_OnboardingNoMFA, { key: 2 }))
                : _createCommentVNode("", true)
            ]))
    ])
  ]))
}