import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "trigger__label" }
const _hoisted_3 = {
  key: 0,
  class: "switch-locale__dropdown"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "switch-locale__dropdown__item__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["switch-locale", _ctx.active ? 'switch-locale--active' : ''])
  }, [
    _createElementVNode("button", {
      class: "trigger",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("img", {
        src: `/img/icons/country/${_ctx.currentLocale.flag}.svg`,
        alt: _ctx.currentLocale.flag,
        class: "trigger__flag"
      }, null, 8, _hoisted_1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.currentLocale.shortLabel), 1),
      _createVNode(_component_OIcon, {
        class: "trigger__icon",
        icon: "chevron_up"
      })
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAvailableLanguages(), (locale, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `lang-${i}`,
                  onClick: ($event: any) => (_ctx.setLanguage(locale.id)),
                  class: _normalizeClass(["switch-locale__dropdown__item", 
            locale.id === _ctx.currentLocale.id
              ? 'switch-locale__dropdown__item--active'
              : ''
          ])
                }, [
                  _createElementVNode("img", {
                    src: `/img/icons/country/${locale.flag}.svg`,
                    alt: locale.flag,
                    class: "switch-locale__dropdown__item__flag"
                  }, null, 8, _hoisted_5),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(locale.label), 1)
                ], 10, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2)), [
    [_directive_on_click_outside, _ctx.onClickOutside]
  ])
}