import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-reference" }
const _hoisted_2 = { class: "layout__content layout__content--large" }
const _hoisted_3 = { class: "order-reference__title" }
const _hoisted_4 = { class: "title title--direct" }
const _hoisted_5 = { class: "layout__intro-text--orders" }
const _hoisted_6 = { class: "order-reference__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OField = _resolveComponent("OField")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      name: "order-reference",
      rules: "noSpecialCharacters"
    }, {
      default: _withCtx(({ errors, field }) => [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('orders.reference.title')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('orders.reference.intro')), 1)
          ]),
          _createVNode(_component_OField, {
            class: "order-reference__number",
            variant: errors.length ? 'danger' : '',
            message: errors.length ? errors[0] : ''
          }, {
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('orders.reference.number.title')) + " ", 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('orders.reference.number.max-characters')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_OInput, _mergeProps(field, {
                modelValue: _ctx.orderReference,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOrderReference($event))),
                placeholder: _ctx.$t('orders.reference.number.placeholder'),
                class: "input--order-reference",
                maxlength: "20"
              }), null, 16, ["modelValue", "placeholder"]),
              _createElementVNode("div", {
                class: _normalizeClass(["order-reference__validation-icon", {
              'order-reference__validation-icon--active': _ctx.active,
              'order-reference__validation-icon--danger': errors.length,
            }])
              }, [
                (!errors.length)
                  ? (_openBlock(), _createBlock(_component_OIcon, {
                      key: 0,
                      icon: "check"
                    }))
                  : (_openBlock(), _createBlock(_component_OIcon, {
                      key: 1,
                      icon: "close",
                      variant: "danger"
                    }))
              ], 2)
            ]),
            _: 2
          }, 1032, ["variant", "message"])
        ])
      ]),
      _: 1
    })
  ]))
}