import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "block__top" }
const _hoisted_2 = { class: "block__header" }
const _hoisted_3 = { class: "block__title" }
const _hoisted_4 = { class: "block__details" }
const _hoisted_5 = { class: "block__details-column" }
const _hoisted_6 = { class: "block__subtitle" }
const _hoisted_7 = { class: "block__text" }
const _hoisted_8 = {
  key: 0,
  class: "block__separator-wrapper"
}
const _hoisted_9 = {
  key: 1,
  class: "block__details"
}
const _hoisted_10 = { class: "block__subtitle" }
const _hoisted_11 = { class: "block__bottom" }
const _hoisted_12 = { class: "block__title" }
const _hoisted_13 = { class: "block__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_InvoiceStatus = _resolveComponent("InvoiceStatus")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!

  return (_ctx.isVisibleToUser() && _ctx.active)
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        class: "block block--status block--invoices",
        to: { name: 'invoices' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_OIcon, {
                icon: "invoice",
                class: "block__icon"
              }),
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('dashboard.invoices.your-open-invoices')), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "block__separator-wrapper" }, [
              _createElementVNode("div", { class: "block__separator" })
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('dashboard.invoices.open-invoices')) + ": ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.totals.total_invoices), 1)
              ])
            ]),
            (_ctx.upcoming)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
                  _createElementVNode("div", { class: "block__separator" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.upcoming)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('dashboard.invoices.upcoming')) + ": ", 1),
                  (_ctx.upcoming)
                    ? (_openBlock(), _createBlock(_component_InvoiceStatus, {
                        key: 0,
                        invoice: _ctx.upcoming
                      }, null, 8, ["invoice"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t('dashboard.invoices.my-invoices')), 1),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('dashboard.invoices.go-to-my-invoices')), 1),
            _createVNode(_component_OIcon, {
              icon: "arrow_back",
              class: "block__icon"
            })
          ])
        ]),
        _: 1
      }))
    : (_ctx.isVisibleToUser())
      ? (_openBlock(), _createBlock(_component_OSkeleton, {
          key: 1,
          animated: true,
          "root-class": "is-right",
          width: "96%",
          height: "320px"
        }))
      : _createCommentVNode("", true)
}