<template>
  <section class="profile layout__content">
    <transition name="fade" v-if="canEditProfile">
      <div
        class="form-card profile-card"
        v-if="!formGroups.profile.active"
        key="p-inactive"
      >
        <div class="form-card__header">
          <h2 class="subtitle">{{ $t('profile.title') }}</h2>
        </div>
        <div class="form-card__body">
          <div class="form-card__details">
            <span
              class="profile__value profile__value--no-data"
              v-if="!formGroupHasData('profile')"
            >
              {{ $t('profile.no-profile-placeholder') }}
            </span>
            <h3 v-if="fullName" class="profile__value profile__value--title">
              {{ fullName }}
            </h3>
            <div
              class="profile__value profile__value--subtitle"
              v-if="profile.functionTitle"
            >
              <label class="profile__label">
                {{ profile.functionTitle }}
              </label>
            </div>
            <div class="profile__value" v-if="profile.city || profile.country">
              <label class="profile__label">
                {{ profile.city }}<span v-if="profile.city">, </span>
                {{
                  $profile.getCountryNameFromEnGb(profile.country, $i18n.locale)
                }}
              </label>
            </div>
          </div>
          <OIcon
            v-if="isEditable('profile')"
            icon="edit"
            class="form-card__button-icon edit-button--profile"
            :alt="$t('profile.edit')"
            @click="edit('profile')"
          />
        </div>
      </div>
      <div class="form-card account-card" v-else key="p-active">
        <div class="form-card__header form-card__header--edit">
          <h2 class="subtitle">{{ $t('profile.title') }}</h2>
          <span class="label">{{ $t('profile.label.edit') }}</span>
        </div>
        <div class="form-card__body">
          <form-group
            name="profile"
            @cancel="cancel('profile')"
            @submit.prevent="save('profile')"
            @setField="setField('profile', $event.name, $event.value)"
            :group="formGroups.profile"
            :profile="profile"
          />
        </div>
      </div>
    </transition>

    <div class="form-card">
      <div
        class="form-card__header"
        v-if="
          !formGroups.email.active &&
          !formGroups.password.active &&
          !formGroups.locale.active
        "
      >
        <h2 class="subtitle">{{ $t('profile.account-settings.title') }}</h2>
      </div>
      <div v-else class="form-card__header form-card__header--edit">
        <h2 class="subtitle">{{ $t('profile.account-settings.title') }}</h2>
        <span class="label">{{ $t('profile.account-settings.edit') }}</span>
      </div>
      <div class="form-card__body">
        <div class="form-card__details">
          <div class="form-card__group">
            <transition name="fade">
              <div class="profile__value" v-if="!formGroups.email.active">
                <label class="profile__label">
                  {{ profile.email }}
                </label>
                <OIcon
                  v-if="isEditable('email')"
                  icon="edit"
                  class="form-card__button-icon edit-button--email"
                  :alt="$t('profile.label.edit')"
                  @click="edit('email')"
                />
              </div>
              <form-group
                v-else
                name="email"
                @cancel="cancel('email')"
                @submit.prevent="save('email')"
                @setField="setField('email', $event.name, $event.value)"
                :group="formGroups.email"
                :profile="profile"
              />
            </transition>
          </div>

          <div class="form-card__group">
            <transition name="fade">
              <div class="profile__value" v-if="!formGroups.password.active">
                <label class="profile__label">
                  {{ $t('profile.label.change-password') }}
                </label>
                <OIcon
                  v-if="isEditable('password')"
                  icon="edit"
                  class="form-card__button-icon edit-button--password"
                  :alt="$t('profile.label.edit')"
                  @click="edit('password')"
                />
              </div>
              <form-group
                v-else
                name="password"
                @cancel="cancel('password')"
                @submit.prevent="save('password')"
                @setField="setField('password', $event.name, $event.value)"
                :group="formGroups.password"
                :profile="profile"
              />
            </transition>
          </div>

          <div
            class="form-card__group form-card__group--labeled"
            v-if="!hasPosessionOfLanguage"
          >
            <h4 class="form-card__label" v-if="!formGroups.locale.active">
              {{ $t('profile.label.locale') }}
            </h4>
            <transition name="fade">
              <div class="profile__value" v-if="!formGroups.locale.active">
                <label class="profile__label">
                  <LanguageLabel :locale="profile.locale"></LanguageLabel>
                </label>
                <OIcon
                  v-if="isEditable('locale')"
                  icon="edit"
                  class="form-card__button-icon edit-button--locale"
                  :alt="$t('profile.label.edit')"
                  @click="edit('locale')"
                />
              </div>
              <form-group
                v-else
                name="locale"
                @cancel="cancel('locale')"
                @submit.prevent="save('locale')"
                @setField="setField('locale', $event.name, $event.value)"
                :group="formGroups.locale"
                :profile="profile"
              />
            </transition>
          </div>

          <div class="form-card__group form-card__group--labeled">
            <h4 class="form-card__label">
              {{ $t('profile.mfa.setup-mfa') }}
            </h4>

            <div class="profile__value" v-if="!formGroups.phone.active">
              <label class="profile__label">
                {{ $t('profile.label.phone') }}:
                {{ profile.phone }}
              </label>
              <OIcon
                v-if="isEditable('phone')"
                icon="edit"
                class="form-card__button-icon edit-button--phone"
                :alt="$t('profile.label.edit')"
                @click="edit('phone')"
              />
            </div>
            <form-group
              v-else
              name="phone"
              @cancel="cancel('phone')"
              @submit.prevent="save('phone')"
              @setField="setField('phone', $event.name, $event.value)"
              :group="formGroups.phone"
              :profile="profile"
            />

            <div class="profile__value" v-if="!formGroups.mfa.active">
              <label class="profile__label">
                {{ $t('profile.mfa.current-mfa') }}
                {{ $t(`profile.mfa.method.${preferredMFA}`) }}
              </label>
              <OIcon
                v-if="isEditable('mfa')"
                icon="edit"
                class="form-card__button-icon"
                :alt="$t('profile.label.edit')"
                @click="showMFaOnboarding()"
              />
            </div>
            <OModal
              :active="$store.getters['auth/showMFAOnboarding']"
              trap-focus
              :cancelable="true"
              @close="hideMFaOnboarding"
              aria-role="dialog"
            >
              <Onboarding />
            </OModal>
          </div>
        </div>
      </div>
    </div>
    <OLoading :active="loading" :full-page="true" />
  </section>
</template>

<script lang="ts">
import { Hub } from 'aws-amplify/utils';
import { I18n } from '@aws-amplify/core';
import { Profile } from '@/api/model';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import FormGroup from '@/components/profile/FormGroup.vue';
import LanguageLabel from '@/components/profile/LanguageLabel.vue';
import AmplifyI18n from '@/components/authentication/AmplifyI18n';
import AuthGroup from '@/logic/auth/AuthGroup';
import { defineComponent } from 'vue';
import {
  updatePassword,
  updateUserAttributes,
  confirmUserAttribute,
  fetchUserAttributes,
} from 'aws-amplify/auth';
import BaseDialog from '@/components/base/BaseDialog.vue';
import Onboarding from '@/components/onboarding/TheOnboarding.vue';

const cognitoFormGroups = ['email', 'password'];

export default defineComponent({
  components: {
    Onboarding,
    FormGroup,
    LanguageLabel,
  },
  mixins: [MessageToastMixin],
  data() {
    const formGroups: any = {
      profile: {
        active: false,
        fields: [
          { name: 'firstName' },
          { name: 'lastName' },
          { name: 'functionTitle' },
          { name: 'city' },
          { name: 'country', type: 'country' },
        ],
      },
      email: {
        active: false,
        fields: [{ name: 'email', type: 'email' }],
      },
      password: {
        active: false,
        fields: [
          { name: 'oldPassword', type: 'password' },
          { name: 'newPassword', type: 'password' },
          { name: 'newPasswordRepeat', type: 'password' },
        ],
      },
      locale: {
        active: false,
        fields: [{ name: 'locale', type: 'locale' }],
      },
      phone: {
        active: false,
        fields: [{ name: 'phone', type: 'tel' }],
      },
      mfa: {
        active: false,
        fields: [{ name: 'mfa' }],
      },
    };

    return {
      formGroups,
      loading: false,
    };
  },
  computed: {
    canEditProfile(): boolean {
      return !this.$store.getters['auth/memberOf'](AuthGroup.Multinational);
    },
    profile(): Profile {
      return this.$store.state.profile.profile;
    },
    fullName(): string {
      return this.$store.getters['profile/fullName'];
    },
    user(): any {
      return this.$store.state.auth.user;
    },
    mfaPreference(): any {
      return this.$store.state.auth.mfaPreference;
    },
    hasPosessionOfLanguage(): boolean {
      return this.$store.getters['auth/memberOf'](AuthGroup.Multinational);
    },
    mfaConfig(): any {
      const mfaTypes = ['TOTP', 'None'];
      if (
        this.user &&
        this.user.attributes &&
        this.user.attributes.phone_number
      ) {
        mfaTypes.push('SMS');
      }
      return {
        mfaDescription: this.$t('profile.mfa.mfa-description'),
        mfaTypes,
        tokenInstructions: this.$t('profile.mfa.token-instructions'),
        smsDescription: this.$t('profile.mfa.sms-description'),
        totpDescription: this.$t('profile.mfa.totp-description'),
        noMfaDescription: this.$t('profile.mfa.no-mfa-description'),
        cancelHandler: () => {
          this.cancel('mfa');
        },
      };
    },
    preferredMFA(): string {
      return this.mfaPreference?.preferred ?? 'NOMFA';
    },
  },
  async created() {
    await this.$store.dispatch('auth/getUser');
    Hub.listen('auth', this.onAuthStateChange);

    I18n.putVocabularies(AmplifyI18n);
  },
  methods: {
    async onAuthStateChange(data: any) {
      const { payload } = data;

      if (payload?.event === 'signedIn' && this.formGroups.mfa.active) {
        this.$store.dispatch('auth/disableMFAOnboarding');
        this.showSuccess(this.$t('profile.error.success'));
        this.cancel('mfa');
      }
    },
    edit(formGroup: string) {
      this.formGroups[formGroup].active = true;
      Object.keys(this.formGroups)
        .filter((group) => group !== formGroup)
        .forEach((group) => {
          this.formGroups[group].active = false;
        });
    },
    showMFaOnboarding() {
      this.$store.dispatch('auth/enableMFAOnboarding');
    },
    hideMFaOnboarding() {
      this.$store.dispatch('auth/disableMFAOnboarding');
    },
    cancel(formGroup: string) {
      this.formGroups[formGroup].active = false;
    },
    formGroupHasData(formGroup: string): boolean {
      return this.formGroups[formGroup].fields.some(
        // @ts-ignore
        (field) => this.profile[field.name],
      );
    },
    async save(formGroup: string) {
      const group = this.formGroups[formGroup];
      const newFields: any = {};
      group.fields.forEach((f: any) => {
        if (f.value !== undefined) {
          newFields[f.name] = f.value;
        }
      });
      const newProfile = Object.assign(this.profile, newFields);

      // Sync to cognito
      if (cognitoFormGroups.includes(formGroup)) {
        try {
          // Password
          if (formGroup === 'password') {
            const success = await this.savePassword(newFields);
            if (!success) {
              return;
            }
          } else {
            this.saveAttributes(newFields, formGroup);
          }
        } catch (e) {
          this.showError(this.$t('profile.password.invalid'));
          return;
        }
      }

      // Sync to aws database
      try {
        if (formGroup !== 'password') {
          this.loading = true;
          await this.$store.dispatch('profile/saveProfile', newProfile);
          this.loading = false;
        }
        group.active = false;
        this.showSuccess(this.$t('profile.error.success'));
      } catch (e) {
        this.showError(this.$t('profile.error.failed'));
      }
    },
    setField(formGroup: string, fieldName: string, value: string) {
      const field = this.formGroups[formGroup].fields.find(
        (f: any) => f.name === fieldName,
      );
      field.value = value;
      this.$store.commit('profile/setProfileField', { fieldName, value });
    },
    async savePassword(fields: any): Promise<boolean> {
      if (fields.newPassword !== fields.newPasswordRepeat) {
        this.showError(this.$t('profile.password.do-not-match'));
        return false;
      }

      await updatePassword({
        oldPassword: fields.oldPassword,
        newPassword: fields.newPassword,
      });
      this.showSuccess(this.$t('profile.password.change-success'));
      return true;
    },
    async saveAttributes(fields: any, formGroup: string) {
      const attributes = await fetchUserAttributes();
      // Only sync existing fields
      const newAttributes: any = {};
      if (fields.email && attributes.email !== fields.email) {
        newAttributes.email = fields.email;
      }
      if (
        fields.phone !== undefined &&
        attributes.phone_number !== fields.phone
      ) {
        newAttributes.phone_number = fields.phone.replaceAll(' ', '');
      }
      if (fields.locale && attributes.locale !== fields.locale) {
        newAttributes.locale = fields.locale;
      }
      try {
        await updateUserAttributes({ userAttributes: newAttributes });
      } catch (e: any) {
        if (e.code === 'InvalidParameterException') {
          this.showError(this.$t('profile.phone.invalid-phone-number'));
        } else {
          this.showError(this.$t('profile.phone.unknown-error'));
        }
      }

      if (formGroup === 'email') {
        this.showEmailConfirm();
      }

      if (formGroup === 'locale') {
        this.showSuccess(this.$t('profile.locale.change-success'));
      }
    },
    showEmailConfirm() {
      this.$oruga.modal.open({
        component: BaseDialog,
        props: {
          message: this.$t('profile.email.confirmation-code') as string,
          inputAttrs: {
            type: 'text',
            placeholder: this.$t('profile.placeholder.confirmation-code'),
            modelValue: '',
          },
          inputField: true,
          confirmText: this.$t('profile.label.save') as string,
          cancelText: this.$t('user-management.status.cancel') as string,
          onConfirm: (code: string, { close }: { close: any }) =>
            this.confirmEmail(code, close),
        },
        canCancel: false,
        closeOnConfirm: false,
        trapFocus: true,
      });
    },
    async confirmEmail(confirmationCode: string, close: () => void) {
      try {
        await confirmUserAttribute({
          userAttributeKey: 'email',
          confirmationCode,
        });
        this.showSuccess(this.$t('profile.email.confirmed'));
        close();
      } catch (e) {
        this.showError(this.$t('profile.email.error-confirm-code'));
      }
    },
    isEditable(formGroup: string): boolean {
      if (
        (cognitoFormGroups.includes(formGroup) || formGroup === 'mfa') &&
        this.$store.getters['profile/isImpersonated']
      ) {
        return false;
      }
      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
.profile {
  &__value {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--title {
      margin-bottom: 10px;
      font-size: 25px;
      letter-spacing: 1.63px;
      color: $primary;

      @include desktop {
        font-size: 30px;
      }
    }

    &--subtitle {
      font-weight: bold;
      letter-spacing: 0.8px;
      color: rgba(64, 64, 64, 0.7);
    }

    &--no-data {
      color: rgba(64, 64, 64, 0.7);
      letter-spacing: 0.2px;
    }
  }

  &__label {
    line-height: 30px;
  }

  :deep(.phone) {
    width: 100%;
  }
}
</style>
