import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field-body" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    name: _ctx.$attrs.name,
    vid: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules,
    slim: ""
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_OField, {
            label: _ctx.$attrs.label,
            message: errors.length ? errors[0] : '',
            class: _normalizeClass(["date-range", _ctx.classNames(errors)]),
            type: errors.length ? 'is-danger' : ''
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OSelect, {
                placeholder: _ctx.$attrs.placeholder,
                "onUpdate:modelValue": _ctx.onInput,
                modelValue: _ctx.getValue
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearArray, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: option.id,
                      key: option.id
                    }, _toDisplayString(option.value), 9, _hoisted_3))
                  }), 128))
                ]),
                _: 1
              }, 8, ["placeholder", "onUpdate:modelValue", "modelValue"])
            ]),
            _: 2
          }, 1032, ["label", "message", "class", "type"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["name", "vid", "rules"]))
}