import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "block image-carousel" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OCarouselItem = _resolveComponent("OCarouselItem")!
  const _component_OCarousel = _resolveComponent("OCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.block)
      ? (_openBlock(), _createBlock(_component_OCarousel, {
          key: 0,
          indicators: false,
          "icon-next": "arrow_forward",
          "icon-prev": "arrow_back",
          "arrows-hover": false,
          overlay: _ctx.gallery,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchGallery(true)))
        }, {
          indicators: _withCtx(({ active, switchTo }) => [
            _createVNode(_component_OCarousel, {
              arrows: false,
              "model-value": active,
              indicators: false,
              "items-to-show": 2.5,
              breakpoints: { 768: { itemsToShow: 4.5 } },
              "onUpdate:modelValue": ($event: any) => (switchTo($event)),
              "root-class": "carousel-list"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
                  return (_openBlock(), _createBlock(_component_OCarouselItem, {
                    key: i,
                    clickable: "",
                    "item-class": "img-indicator",
                    "item-active-class": "img-indicator-active"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: image.image,
                        alt: ""
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue"])
          ]),
          overlay: _withCtx(() => [
            (_ctx.gallery)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "modal-close is-large",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchGallery(false)))
                }))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.imagecarousel, (images, i) => {
              return (_openBlock(), _createBlock(_component_OCarouselItem, { key: i }, {
                default: _withCtx(() => [
                  _createElementVNode("figure", _hoisted_2, [
                    _createElementVNode("img", {
                      src: _ctx.$helpers.getApiImageThumbnail(images.image),
                      alt: ""
                    }, null, 8, _hoisted_3)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["overlay"]))
      : _createCommentVNode("", true)
  ]))
}