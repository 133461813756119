import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "steps-bar" }
const _hoisted_2 = { class: "steps-bar__steps" }
const _hoisted_3 = { class: "steps-bar__step__index" }
const _hoisted_4 = { class: "steps-bar__step__label" }
const _hoisted_5 = { class: "is-hidden-mobile" }
const _hoisted_6 = { class: "is-hidden-tablet" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["steps-bar__step", { 'steps-bar__step--active': _ctx.active(step) }]),
          key: index
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1) + ":", 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.labelPrefix}.new.steps.${step}`)), 1)
        ], 2))
      }), 128))
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.active(_ctx.firstStep()))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "steps-bar__overview-button",
              to: _ctx.overviewLink
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.labelPrefix}.new.repeat-button.large1`)), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`${_ctx.labelPrefix}.new.repeat-button.large2`)), 1)
                  ])
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.labelPrefix}.new.repeat-button.small`)), 1),
                _createVNode(_component_OIcon, { icon: "arrow_back" })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}