import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table__details invoice-details" }
const _hoisted_2 = { class: "invoice-details__header-container columns" }
const _hoisted_3 = { class: "invoice-details__header column" }
const _hoisted_4 = { class: "invoice-details__status is-hidden-mobile" }
const _hoisted_5 = { class: "invoice-details__section invoice-details__body columns" }
const _hoisted_6 = {
  key: 0,
  class: "invoice-details__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadInvoicePdf = _resolveComponent("DownloadInvoicePdf")!
  const _component_ShowSalesOrder = _resolveComponent("ShowSalesOrder")!
  const _component_InvoiceStatus = _resolveComponent("InvoiceStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (
            _ctx.$store.getters['auth/hasAccess']({
              action: 'GET',
              resource: '/invoice-download-link',
            })
          )
          ? (_openBlock(), _createBlock(_component_DownloadInvoicePdf, {
              key: 0,
              class: "invoice-details__header-button",
              invoice: _ctx.invoice
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('invoices.detail.pdf-download')), 1)
              ]),
              _: 1
            }, 8, ["invoice"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ShowSalesOrder, {
          class: "invoice-details__header-button",
          label: _ctx.$t('invoices.detail.show-sales-order'),
          invoice: _ctx.invoice
        }, null, 8, ["label", "invoice"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InvoiceStatus, { invoice: _ctx.invoice }, null, 8, ["invoice"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (column) => {
        return _createElementVNode("div", {
          class: "column",
          key: column
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.filter((f) => f.col === column), (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: field.label,
              class: "invoice-details__field"
            }, [
              _createElementVNode("label", {
                class: _normalizeClass(["invoice-details__label", {
              'invoice-details__label--separator': field.label === 'separator',
            }])
              }, _toDisplayString(field.label !== 'separator'
                ? _ctx.$t(`invoices.detail.label.${field.label}`)
                : ''), 3),
              (field.label !== 'separator')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(field.value || '-'), 1))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      }), 64))
    ])
  ]))
}