import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "totals" }
const _hoisted_2 = { class: "totals__label" }
const _hoisted_3 = { class: "totals__item totals__item--volume" }
const _hoisted_4 = { class: "totals__item totals__item--amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCurrencyAmount = _resolveComponent("BaseCurrencyAmount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('claims.new.footer.total-billback-amount')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.totals.volume) + " " + _toDisplayString(_ctx.$t('claims.kg')), 1),
    _createElementVNode("span", _hoisted_4, [
      (_ctx.agreement?.currency)
        ? (_openBlock(), _createBlock(_component_BaseCurrencyAmount, {
            key: 0,
            currency: _ctx.agreement.currency,
            amount: _ctx.totals.billbackAmount
          }, null, 8, ["currency", "amount"]))
        : _createCommentVNode("", true)
    ])
  ]))
}