import { defineRule, configure } from 'vee-validate';
import { email, ext, integer, required } from '@vee-validate/rules';
import parse from 'date-fns/parse';
import { FieldValidationMetaInfo } from '@vee-validate/i18n';
import i18n from '@/i18n';

configure({
  generateMessage: (context: FieldValidationMetaInfo): string => {
    const ruleName = context.rule?.name;
    const values = context.value as Record<string | number, any>;

    // accepted extension are added like an array, this converts it to a comma seperated string.
    if (ruleName && ruleName === 'ext') {
      const keys = Object.keys(values).filter((key) => key.charAt(0) !== '_');
      values.ext = keys.map((key) => values[key]).join(', ');
    }

    if (ruleName && ruleName === 'totalSize') {
      values.totalSize = values['0'];
    }

    return i18n.global.t(`validation.${ruleName}`, values) as string;
  },
});

// Built-in rules
defineRule('email', email);
defineRule('ext', ext);
defineRule('integer', integer);
defineRule('required', required);

defineRule('password', (value: string) => {
  if (!value || !value.length) {
    return true;
  }

  if (value.length < 8) {
    // @ts-ignore
    return i18n.global.t('validation.password', { length: 8 }) as string;
  }

  return true;
});

defineRule(
  'password-repeat',
  (value: string, [target]: string): boolean | string => value === target,
);

defineRule('noSpecialCharacters', (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  const forbiddenChars = ['#', '"', "'", '*', ','];
  if (forbiddenChars.some((char: string) => value.includes(char))) {
    // @ts-ignore
    return i18n.global.t('validation.no-special-characters');
  }
  return true;
});

defineRule('totalSize', (values: any, params: any[] | Record<string, any>) => {
  const totalSize = values.reduce(
    (Accsize: number, file: File) => Accsize + file.size,
    0,
  );

  if (Array.isArray(params)) {
    return totalSize / 1024 / 1024 <= params[0];
  }

  return true;
});

const getQuarter = (date: Date): number => {
  const month = date.getMonth();
  return Math.floor(month / 3) + 1;
};

const getQuarterMonths = (quarter: number): number[] => {
  const startMonth = (quarter - 1) * 3;
  const endMonth = startMonth + 2;
  return [startMonth, endMonth];
};

defineRule('quarterly', (value: any, params: any[] | Record<string, any>) => {
  const [start, end] = value;
  if (!start || !end) {
    return false;
  }

  const quarterStart = getQuarter(start);

  // Check if dates are within the same quarter and year
  if (
    quarterStart !== getQuarter(end) ||
    start.getFullYear() !== end.getFullYear()
  ) {
    return false;
  }

  // Check if the start matches the start of a quarter and the end matches the end of a quarter, this makes sure the dates are not in the middle of a quarter.
  const [startMonth, endMonth] = getQuarterMonths(quarterStart);
  if (start.getMonth() !== startMonth || end.getMonth() !== endMonth) {
    // Check to make sure the start or end of the agreement is not between the start and end of the quarter, if agreement starts or stops halfway the quarter it's valid.
    if (Array.isArray(params)) {
      const [agreementStart, agreementEnd] = params;
      const agreementStartDate = parse(
        agreementStart,
        'yyyy-MM-dd',
        new Date(Date.now()),
      );
      const agreementEndDate = parse(
        agreementEnd,
        'yyyy-MM-dd',
        new Date(Date.now()),
      );

      if (
        (agreementStartDate.getFullYear() === start.getFullYear() &&
          agreementStartDate.getMonth() > startMonth &&
          agreementStartDate.getMonth() < endMonth) ||
        (agreementEndDate.getFullYear() === end.getFullYear() &&
          agreementEndDate.getMonth() > startMonth &&
          agreementEndDate.getMonth() < endMonth)
      ) {
        return true;
      }
    }
    return false;
  }

  // No reason to return false, so it's valid
  return true;
});
