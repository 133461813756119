import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-claim-footer new-claim-footer--sticky" }
const _hoisted_2 = { class: "new-claim-footer__content" }
const _hoisted_3 = { class: "new-order-footer__totals" }
const _hoisted_4 = { class: "new-claim-footer__nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewClaimFooterTotals = _resolveComponent("NewClaimFooterTotals")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showTotals && _ctx.agreement)
          ? (_openBlock(), _createBlock(_component_NewClaimFooterTotals, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("nav", _hoisted_4, [
        _createVNode(_component_OButton, {
          variant: "primary large new-claim-footer__button",
          rounded: "",
          outlined: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goPrevious())),
          "data-test": "previous-step"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('claims.new.footer.back')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_OButton, {
          variant: "primary large new-claim-footer__button",
          rounded: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goNext())),
          "data-test": "next-step",
          disabled: !_ctx.canGoNext
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`claims.new.footer.${_ctx.nextLabel}`)), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createVNode(_component_OLoading, {
      active: _ctx.loading,
      "full-page": true
    }, null, 8, ["active"])
  ]))
}