import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_OSelect, {
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _ctx.input
    }, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("option", { value: '' }, null, -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$profile.getCountryCodes(), (code) => {
          return (_openBlock(), _createElementBlock("option", {
            key: code,
            value: _ctx.$profile.getCountryNameFromCode(code, 'en-GB')
          }, _toDisplayString(_ctx.$profile.getCountryNameFromCode(code, _ctx.$i18n.locale)), 9, _hoisted_1))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}